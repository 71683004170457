import { useState, type ReactNode } from 'react';
import { getRouteApi } from '@tanstack/react-router';

import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { useTenants } from 'services/api/tenants';

import { Drawer } from 'components/common/Drawer';
import { Button } from 'components/common/Button';
import { Authorize } from 'components/common/Authorize';
import { VerticalTabContent, VerticalTabTrigger, VerticalTabs, VerticalTabsList } from 'components/common/VerticalTabs';

import { SettingsEditTabContent, SettingsTabItem } from './tabs/SettingsTab';
import { UsersTabContent, UsersTabItem } from './tabs/UsersTab';
import { DeleteTenantInlineDialog } from './DeleteTenantInlineDialog';

const routeApi = getRouteApi('/_app/settings/platform/tenants');

type EditTenantSidebarProps = {
  onSuccess: (tenant: components['schemas']['tenant']) => void;
};

export const EditTenantSidebar = ({ onSuccess }: EditTenantSidebarProps) => {
  const t = useTranslate();
  const navigate = routeApi.useNavigate();
  const searchParams = routeApi.useSearch();
  const tenantsQuery = useTenants();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const tab =
    (('tenantSidebarIntent' in searchParams &&
      searchParams.tenantSidebarIntent === 'edit' &&
      searchParams.tab) as string) || 'settings';
  const isOpen = 'tenantSidebarIntent' in searchParams && searchParams.tenantSidebarIntent === 'edit';
  const tenantId = ('tenantSidebarIntent' in searchParams &&
    searchParams.tenantSidebarIntent === 'edit' &&
    searchParams.tenantId) as string;

  const tenant = tenantsQuery.data?.data.find((t) => t.id === tenantId);

  const errorOrContent = (node: ReactNode) => {
    if (tenantsQuery.error) {
      return 'Error';
    }

    if (tenantsQuery.isLoading) {
      return 'Loading...';
    }

    if (!tenant) {
      return 'Tenant not found';
    }

    return node;
  };

  const onDeleteTenantSuccess = () => {
    setIsDeleteDialogOpen(false);
    void navigate({
      search: {},
    });
  };

  return (
    <Drawer
      title={t('settings.tabs.platform.subtabs.tenants.sidebar.edit_title')}
      description={t('settings.tabs.platform.subtabs.tenants.sidebar.edit_description', { tenantName: tenant?.name })}
      mode="modal"
      side="right"
      insetContent
      isOpen={isOpen}
      actions={
        <Authorize
          policy={{
            scope: 'tenant:delete',
          }}
        >
          <Button size="1" color="red" onClick={() => setIsDeleteDialogOpen(true)}>
            {t('settings.tabs.platform.subtabs.tenants.sidebar.delete_tenant')}
          </Button>
        </Authorize>
      }
      onOpenChange={() => {
        void navigate({
          search: {},
        });
      }}
    >
      <VerticalTabs
        value={tab}
        onValueChange={(tab) => {
          void navigate({
            search: (prev) => ({
              ...prev,
              tab: tab as 'settings' | 'pipelines' | 'users',
            }),
          });
        }}
        size="2"
        orientation="vertical"
        paddingTop={8}
      >
        <VerticalTabsList data-test-id="tenant-sidebar-vertical-tabs">
          <VerticalTabTrigger value="settings" data-test-id="tenant-sidebar-settings-button">
            <SettingsTabItem />
          </VerticalTabTrigger>
          <Authorize
            policy={{
              scope: 'user:read',
            }}
          >
            <VerticalTabTrigger value="users" data-test-id="tenant-sidebar-users-button">
              <UsersTabItem />
            </VerticalTabTrigger>
          </Authorize>
        </VerticalTabsList>

        <VerticalTabContent value="settings">
          {isDeleteDialogOpen && tenant && (
            <DeleteTenantInlineDialog
              tenant={tenant}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onSuccess={onDeleteTenantSuccess}
            />
          )}
          {errorOrContent(<SettingsEditTabContent tenant={tenant!} onSuccess={onSuccess} />)}
        </VerticalTabContent>
        <VerticalTabContent value="users">
          {isDeleteDialogOpen && tenant && (
            <DeleteTenantInlineDialog
              tenant={tenant}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onSuccess={onDeleteTenantSuccess}
            />
          )}
          {errorOrContent(<UsersTabContent tenant={tenant!} onSuccess={onSuccess} />)}
        </VerticalTabContent>
      </VerticalTabs>
    </Drawer>
  );
};
