import { useMemo, useState, useEffect } from 'react';
import * as z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from '@tanstack/react-router';
import { zodResolver } from '@hookform/resolvers/zod';

import { handleKeyDown } from 'utils/handleKeyDown';
import { copyToClipboard } from 'utils/copyToClipboard';
import { useRoles } from 'services/api/roles';
import { useTenants } from 'services/api/tenants';
import { useGetUserInviteLink, useGetUserResetPasswordLink } from 'services/api/auth';
import { useTranslate } from 'services/i18n/useTranslate';
import { useCreateUser, useEditUser } from 'services/api/users';
import * as errorMessages from 'consts/errorMessages';
import { type components } from 'types/schemas/api-schema';

import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { Button } from 'components/common/Button';
import { Switch } from 'components/common/Switch';
import { Heading } from 'components/common/Heading';
import { Callout } from 'components/common/Callout';
import { TextField } from 'components/common/TextField';
import { Collapsible } from 'components/common/Collapsible';
import { LayersIcon, PersonIcon } from 'components/common/Icons';
import { ReadScopeBadge, WriteScopeBadge, ScopeBadges } from 'components/common/Badge/ScopeBadge';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/common/Form';

import { Section } from '../../components/Section';

import type { ViewMode } from './useSearchParams';
import { UserStatusBadge } from './UserStatusBadge';
import { CopyInvitationLinkButton } from './CopyInvitationLinkButton';

const userSchema = z.object({
  firstName: z.string().min(1, errorMessages.required),
  lastName: z.string().min(1, errorMessages.required),
  email: z.string().min(1, errorMessages.required).email(errorMessages.email),
  roles: z.array(z.string()),
});

type RoleData = components['schemas']['role'];
type UserData = components['schemas']['user'];
type OpenStates = Record<string, boolean>;
type UserFormFields = z.infer<typeof userSchema>;
type TenantRoles = {
  tenantId: string;
  tenantName: string;
  roles: RoleData[];
};

const LinkActionCallout = ({ isError, onClose }: { isError: boolean; onClose: () => void }) => {
  const t = useTranslate();

  return (
    <Callout variant="soft" status={isError ? 'error' : 'success'} size="1" onClose={onClose}>
      {isError
        ? t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.failed_to_copy_invitation_link')
        : t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.invitation_link_copied')}
    </Callout>
  );
};

export function UserForm({
  user,
  isLoading,
  error,
  onSubmit,
  viewMode,
  onCloseCallout,
}: {
  user?: Partial<UserData>;
  isLoading?: boolean;
  error?: components['schemas']['errorResponse'];
  onSubmit: (data: UserFormFields) => void;
  viewMode: ViewMode;
  onCloseCallout?: () => void;
}) {
  const t = useTranslate();
  const navigate = useNavigate();
  const rolesQuery = useRoles();
  const tenantQuery = useTenants();
  const [allExpanded, setAllExpanded] = useState(false);
  const [individualOpenStates, setIndividualOpenStates] = useState<OpenStates>({});
  const getUserInviteLinkMutation = useGetUserInviteLink();
  const getUserResetPasswordLinkMutation = useGetUserResetPasswordLink();

  const userRoles = useMemo(() => {
    if (user?.roles && rolesQuery.data?.data) {
      return rolesQuery.data.data.filter((role) => user.roles!.includes(role.id));
    }
    return [];
  }, [user, rolesQuery.data?.data]);

  const handleRequestReset = () => {
    if (user?.passwordResetRequested) {
      getUserResetPasswordLinkMutation.mutate(
        { email: user.email! },
        {
          onSuccess: (link) => {
            void copyToClipboard(link);
          },
        },
      );
    } else {
      getUserInviteLinkMutation.mutate(
        { email: user!.email! },
        {
          onSuccess: (link) => {
            void copyToClipboard(link);
          },
        },
      );
    }
  };

  const toggleIndividualOpen = (id: string) => {
    setIndividualOpenStates((prevState) => {
      const newState = {
        ...prevState,
        [id]: !prevState[id],
      };

      const allOpen = Object.values(newState).every(Boolean);
      setAllExpanded(allOpen);

      return newState;
    });
  };

  const toggleAll = () => {
    const newState = !allExpanded;
    const newStates = Object.keys(individualOpenStates).reduce((acc: OpenStates, key) => {
      acc[key] = newState;
      return acc;
    }, {});

    setIndividualOpenStates(newStates);
    setAllExpanded(newState);
  };

  const roles = useMemo(() => {
    if (!rolesQuery.data?.data || !tenantQuery.data?.data) {
      return { globalRoles: [], tenantRoles: [] };
    }

    const globalRoles: RoleData[] = [];
    const tenantRoles: TenantRoles[] = [];

    rolesQuery.data.data.forEach((role) => {
      if (!role.ownedTenantId) {
        globalRoles.push(role);
      } else {
        const tenant = tenantQuery.data.data.find((t) => t.id === role.ownedTenantId);
        const tenantName = tenant?.name || 'Unknown Tenant';

        let tenantGroup = tenantRoles.find((t) => t.tenantId === role.ownedTenantId);
        if (!tenantGroup) {
          tenantGroup = { tenantId: role.ownedTenantId, tenantName, roles: [] };
          tenantRoles.push(tenantGroup);
        }
        tenantGroup.roles.push(role);
      }
    });

    return { globalRoles, tenantRoles };
  }, [rolesQuery.data, tenantQuery.data]);

  const form = useForm<UserFormFields>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      roles: user?.roles || [],
    },
  });

  useEffect(() => {
    if (rolesQuery.data) {
      const selectedRoles = form.getValues('roles');
      const initialOpenStates = rolesQuery.data.data.reduce<OpenStates>((acc, role) => {
        if (role.ownedTenantId) {
          const isOpen = selectedRoles.includes(role.id);
          acc[role.ownedTenantId] = acc[role.ownedTenantId] || isOpen;
        }
        return acc;
      }, {});

      setIndividualOpenStates(initialOpenStates);
      setAllExpanded(Object.values(initialOpenStates).some((state) => state));
    }
  }, [form, rolesQuery.data]);

  const handleCancel = () => {
    void navigate({
      search: undefined,
    });
  };

  if (rolesQuery.isLoading) {
    return 'Loading...';
  }

  if (rolesQuery.isError) {
    return 'Error...';
  }

  const isCopyLinkClickable = user?.passwordResetRequested
    ? !getUserResetPasswordLinkMutation.isSuccess
    : !getUserInviteLinkMutation.isSuccess;

  const isLinkCalloutVisible =
    getUserInviteLinkMutation.isSuccess ||
    getUserInviteLinkMutation.isError ||
    getUserResetPasswordLinkMutation.isSuccess ||
    getUserResetPasswordLinkMutation.isError;

  const handleCloseLinkCallout = () => {
    getUserInviteLinkMutation.reset();
    getUserResetPasswordLinkMutation.reset();
  };

  return (
    <Form {...form}>
      <form className="flex h-full flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
        <Section
          icon={PersonIcon}
          title={t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.user_information')}
        >
          <div className="w-[400px] space-y-4">
            {viewMode !== 'create' && (
              <div className="space-y-1">
                <Heading size="2" weight="regular">
                  {t('common.status')}
                </Heading>
                <div className="flex items-center gap-4">
                  <UserStatusBadge
                    status={user?.status || 'active'}
                    passwordResetRequested={!!user?.passwordResetRequested}
                  />
                  {((user && viewMode !== 'view' && user.status === 'invited') || user?.passwordResetRequested) && (
                    <CopyInvitationLinkButton
                      isLinkClickable={isCopyLinkClickable}
                      handleRequestReset={handleRequestReset}
                    />
                  )}
                </div>
                {isLinkCalloutVisible && (
                  <LinkActionCallout
                    isError={getUserInviteLinkMutation.isError || getUserResetPasswordLinkMutation.isError}
                    onClose={handleCloseLinkCallout}
                  />
                )}

                {(getUserInviteLinkMutation.isSuccess || getUserInviteLinkMutation.isError) && (
                  <Callout
                    variant="soft"
                    status={getUserInviteLinkMutation.isError ? 'error' : 'success'}
                    size="1"
                    onClose={() => getUserInviteLinkMutation.reset()}
                  >
                    {getUserInviteLinkMutation.isError
                      ? t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.failed_to_copy_invitation_link')
                      : t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.invitation_link_copied')}
                  </Callout>
                )}
              </div>
            )}
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('common.first_name')}</FormLabel>
                    <FormControl>
                      <TextField readOnly={viewMode === 'view'} placeholder={t('common.first_name')} {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('common.last_name')}</FormLabel>
                    <FormControl>
                      <TextField readOnly={viewMode === 'view'} placeholder={t('common.last_name')} {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('common.email')}</FormLabel>
                    <FormControl>
                      <TextField readOnly={viewMode === 'view'} placeholder={t('common.email')} {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
        </Section>

        <Heading size="3" weight="medium" className="!mb-0 text-neutral-a11">
          {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.permissions')}
        </Heading>

        <Section icon={LayersIcon} title={t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.roles')}>
          {viewMode !== 'view' && (
            <>
              <div className="w-[200px]">
                <Label htmlFor="role-label" className="text-sm font-medium leading-6">
                  Label
                </Label>
                <TextField id="role-label" size="2" variant="soft" placeholder={t('common.search')} />
              </div>

              <Heading size="2" weight="medium" className="text-neutral-a11">
                {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.global_roles')}
              </Heading>

              <div className="rounded-2 border border-neutral-a5 bg-default">
                {roles.globalRoles.map((role) => (
                  <div key={role.id} className="flex items-center gap-1 p-2">
                    <div className="flex min-w-[calc(45%)] items-center gap-4">
                      <Controller
                        name="roles"
                        control={form.control}
                        render={({ field: { onChange, value, ref } }) => {
                          const isRoleSelected = value.includes(role.id);

                          const handleToggleRole = () => {
                            const newRoles = isRoleSelected
                              ? value.filter((id) => id !== role.id)
                              : [...value, role.id];
                            onChange(newRoles);
                          };

                          return (
                            <Switch
                              ref={ref}
                              checked={isRoleSelected}
                              onCheckedChange={handleToggleRole}
                              radius="small"
                              size="1"
                              variant="classic"
                            />
                          );
                        }}
                      />
                      <div className="flex flex-col">
                        <Text size="2">{role.name}</Text>
                        <Text size="2" className="text-neutral-a11">
                          {role.description}
                        </Text>
                      </div>
                    </div>

                    <ScopeBadges scopes={role.scopes} />
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-between">
                {roles.tenantRoles.length > 0 && (
                  <Heading size="2" weight="medium" className="text-neutral-a11">
                    {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.tenant_level_roles')}
                  </Heading>
                )}

                {roles.tenantRoles.length > 0 && (
                  <Text
                    color="orange"
                    size="2"
                    className="cursor-pointer underline hover:text-orange-a12"
                    role="button"
                    onClick={toggleAll}
                    tabIndex={0}
                    onKeyDown={handleKeyDown(() => toggleAll())}
                  >
                    {allExpanded ? t('settings.common.collapse_all') : t('settings.common.expand_all')}
                  </Text>
                )}
              </div>

              {roles.tenantRoles.map((tenantGroup) => (
                <Collapsible
                  key={tenantGroup.tenantId}
                  title={tenantGroup.tenantName}
                  defaultOpen={individualOpenStates[tenantGroup.tenantId]}
                  onOpenChange={() => toggleIndividualOpen(tenantGroup.tenantId)}
                  Icon={LayersIcon}
                >
                  {tenantGroup.roles.map((role) => (
                    <div key={role.id} className="flex items-center gap-1 p-2">
                      <div className="flex min-w-[calc(45%)] items-center gap-4">
                        <Controller
                          name="roles"
                          control={form.control}
                          render={({ field: { onChange, value, ref } }) => {
                            const isRoleSelected = value.includes(role.id);

                            const handleToggleRole = () => {
                              const newRoles = isRoleSelected
                                ? value.filter((id) => id !== role.id)
                                : [...value, role.id];
                              onChange(newRoles);
                            };

                            return (
                              <Switch
                                ref={ref}
                                checked={isRoleSelected}
                                onCheckedChange={handleToggleRole}
                                radius="small"
                                size="1"
                                variant="classic"
                              />
                            );
                          }}
                        />
                        <div className="flex flex-col">
                          <Text size="2" className="capitalize">
                            {role.roleType}
                          </Text>
                          <Text size="2" className="text-neutral-a11">
                            {role.description}
                          </Text>
                        </div>
                      </div>

                      <ScopeBadges scopes={role.scopes} />
                    </div>
                  ))}
                </Collapsible>
              ))}
            </>
          )}

          {viewMode === 'view' && (
            <>
              {userRoles.map((role) => (
                <div key={role.id} className="flex flex-col gap-4">
                  <div className="rounded-2 border border-neutral-a5 bg-solid">
                    <div key={role.id} className="flex items-center gap-1 p-2">
                      <div className="flex min-w-[calc(45%)] items-center gap-4">
                        <div className="flex flex-col">
                          <Text size="2">{role.name}</Text>
                          <Text size="2" className="text-neutral-a11">
                            {role.description}
                          </Text>
                        </div>
                      </div>

                      <ScopeBadges scopes={role.scopes} />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          <div className="flex gap-2 p-2">
            <ReadScopeBadge scopeName={t('settings.common.read_access')} />
            <WriteScopeBadge scopeName={t('settings.common.write_access')} />
          </div>
        </Section>

        <div className="flex-1" />

        {error && (
          <Callout status="error" onClose={onCloseCallout}>
            {error?.errors?.[0]?.message}
          </Callout>
        )}

        {viewMode !== 'view' && (
          <div className="mb-2 flex justify-end gap-2">
            <Button variant="soft" type="button" color="gray" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
            <Button isLoading={isLoading} type="submit">
              {viewMode !== 'create' ? t('common.update') : t('common.save')}
            </Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export type UserContentProps = {
  onSuccess: (user: UserData) => void;
  viewMode: 'create';
};

export const UserCreateContent = ({ onSuccess, viewMode }: UserContentProps) => {
  const navigate = useNavigate();
  const createUserMutation = useCreateUser();

  const handleSubmit = async (data: UserFormFields) => {
    await createUserMutation.mutateAsync(data, {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
    });

    void navigate({
      search: undefined,
    });
  };

  return (
    <UserForm
      isLoading={createUserMutation.isPending}
      error={createUserMutation.error?.json}
      onCloseCallout={createUserMutation.reset}
      onSubmit={handleSubmit}
      viewMode={viewMode}
    />
  );
};

export const UserEditContent = ({
  user,
  viewMode,
  onSuccess,
}: {
  user: UserData;
  viewMode: 'edit' | 'view';
  onSuccess: UserContentProps['onSuccess'];
}) => {
  const navigate = useNavigate();
  const editUserMutation = useEditUser(user.id);

  const handleSubmit = async (data: UserFormFields) => {
    await editUserMutation.mutateAsync(data, {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
    });

    void navigate({
      search: undefined,
    });
  };

  return (
    <UserForm
      isLoading={editUserMutation.isPending}
      error={editUserMutation.error?.json}
      onCloseCallout={editUserMutation.reset}
      user={user}
      onSubmit={handleSubmit}
      viewMode={viewMode}
    />
  );
};
