import { useTranslate } from 'services/i18n/useTranslate';

import { Text } from 'components/common/Text';
import { Select } from 'components/common/Select';
import { IconButton } from 'components/common/IconButton';
import { TriangleLeftIcon, TriangleRightIcon } from 'components/common/Icons';

type PaginationControlPanelProps = {
  pageSize: number;
  onPageSizeChange: (value: string) => void;
  canPrevious: boolean;
  canNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  isLoading: boolean;
  isFetching: boolean;
  totalCount: number;
  pageIndex: number;
};

const SELECT_OPTIONS = [100, 50, 10];

export const PaginationControlPanel = ({
  pageSize,
  onPageSizeChange,
  canPrevious,
  canNext,
  onPrevious,
  onNext,
  isLoading,
  isFetching,
  totalCount,
  pageIndex,
}: PaginationControlPanelProps) => {
  const t = useTranslate();

  return (
    <div className="fixed bottom-0 flex w-full items-center gap-4 border-t border-neutral-8 bg-neutral-3 px-4 py-2">
      <div className="flex items-center gap-4">
        <Text>{t('common.pagination_control_panel.items_per_page')}</Text>
        <Select.Root value={pageSize.toString()} onValueChange={onPageSizeChange}>
          <Select.Trigger />
          <Select.Content>
            {SELECT_OPTIONS.map((value) => (
              <Select.Item key={value} value={value.toString()}>
                {value}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </div>

      {!isLoading && (
        <>
          <Text>
            {t('common.pagination_control_panel.pagination_results', {
              from: pageIndex * pageSize + 1,
              to: Math.min((pageIndex + 1) * pageSize, totalCount),
              total: totalCount,
            })}
          </Text>

          <div className="flex items-center gap-2">
            <IconButton color="gray" onClick={onPrevious} disabled={!canPrevious || isFetching}>
              <TriangleLeftIcon className="size-5" />
            </IconButton>

            <IconButton color="gray" onClick={onNext} disabled={!canNext || isFetching}>
              <TriangleRightIcon className="size-5" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};
