import { useTranslate } from 'services/i18n/useTranslate';

export function EnrichmentFormattingInstructions() {
  const t = useTranslate();
  return (
    <div className="px-3 py-4 text-sm">
      <div className="mb-6">{t('settings.tabs.enrichments.subtabs.manage.sidebar.format_instructions_header')}:</div>

      <code>
        <pre className="mb-6">{t('settings.tabs.enrichments.subtabs.manage.sidebar.format_instructions_body')}</pre>
      </code>

      <div className="mb-1">{t('settings.tabs.enrichments.subtabs.manage.sidebar.format_instructions_label')}:</div>

      <div className="w-[430px] border border-neutral-a7 bg-neutral-6 p-1">
        <pre>
          <code>
            {`ip.address=93.143.102.249; source.importance; 10
subnet=255.255.255.0; source.importance; 9
subnet=255.255.255.0; destination.importance; 6
subnet!=255.255.255.0; source.importance; 9
subnet!=255.255.255.0; destination.importance; 6`}
          </code>
        </pre>
      </div>
    </div>
  );
}
