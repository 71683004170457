import { useEffect } from 'react';
import { getRouteApi } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';

import { BASE_RANGE_OPTIONS } from 'consts/dateTime';
import { isValidTimeRange } from 'utils/dateTimeValidation';
import { resetRelativeQueriesPredicate } from 'utils/detections';

import { PresetDateTimePicker } from 'components/common/PresetDateTimePicker';

import { ThreatsList } from './ThreatsList';
import { Facets } from './Facets';
import { ThreatsProvider, useThreatsContext } from './ThreatsContext';
import { FilterBar } from './FilterBar';
import { Histogram } from './Histogram';

const routeApi = getRouteApi('/_app/threats/');

const ThreatsSceneContent = () => {
  const routeSearch = routeApi.useSearch();
  const queryClient = useQueryClient();
  const { changeTimerange } = useThreatsContext();

  const timeRange = isValidTimeRange(routeSearch.timeRange) ? routeSearch.timeRange : undefined;

  useEffect(() => {
    void queryClient.removeQueries({
      predicate: (query) => {
        return resetRelativeQueriesPredicate('threats', timeRange, query.queryKey);
      },
    });
  }, [queryClient, timeRange]);

  return (
    <ThreatsProvider>
      <div className="flex bg-neutral-3">
        <div className="flex w-[300px] flex-col gap-1">
          <PresetDateTimePicker
            rangeOptions={BASE_RANGE_OPTIONS}
            startTime={routeSearch.startTime}
            endTime={routeSearch.endTime}
            timeRange={timeRange}
            onChange={changeTimerange}
          />

          <Facets />
        </div>
        <div className="w-[calc(100%-300px)]" data-test-id="threats-content-container">
          <FilterBar />
          <Histogram />
          <ThreatsList />
        </div>
      </div>
    </ThreatsProvider>
  );
};

export const ThreatsScene = () => {
  return (
    <ThreatsProvider>
      <ThreatsSceneContent />
    </ThreatsProvider>
  );
};
