import { useMemo, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';

import { useUsers } from 'services/api/users';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';
import type { FlattenedTranslationKeys } from 'types/translations';

import { Drawer } from 'components/common/Drawer';
import { Button } from 'components/common/Button';
import { Authorize } from 'components/common/Authorize';

import type { ViewMode } from './useSearchParams';
import { useSearchParams } from './useSearchParams';
import { DeleteUserInlineDialog } from './DeleteUserInlineDialog';
import { UserCreateContent, UserEditContent, type UserContentProps } from './UserForm';

type UserSidebarProps = {
  viewMode: ViewMode;
  onSuccess: UserContentProps['onSuccess'];
};

const Content = ({
  isLoading,
  isError,
  viewMode,
  user,
  onSuccess,
}: {
  isLoading: boolean;
  isError: boolean;
  viewMode: ViewMode;
  user?: components['schemas']['user'];
  onSuccess: UserContentProps['onSuccess'];
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError || (!user && viewMode !== 'create')) {
    return <div>Error or user not found</div>;
  }
  if (viewMode === 'create') {
    return <UserCreateContent onSuccess={onSuccess} viewMode={viewMode} />;
  }
  if (user) {
    return <UserEditContent onSuccess={onSuccess} viewMode={viewMode} user={user} />;
  }
};

const VIEW_MODE_LABELS: Record<ViewMode, { title: FlattenedTranslationKeys; description: FlattenedTranslationKeys }> = {
  create: {
    title: 'settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.create_title',
    description: 'settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.create_description',
  },
  edit: {
    title: 'settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.edit_title',
    description: 'settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.update_user_info',
  },
  view: {
    title: 'settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.view_title',
    description: 'settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.current_user_info',
  },
};

export const UserSidebar = ({ viewMode, onSuccess }: UserSidebarProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const userQuery = useUsers();
  const searchParams = useSearchParams();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const isOpen = 'userSidebarIntent' in searchParams && searchParams.userSidebarIntent === viewMode;
  const userId = ('userSidebarIntent' in searchParams &&
    searchParams.userSidebarIntent !== 'create' &&
    searchParams.userId) as string;
  const user = useMemo(() => userQuery.data?.data.find((u) => u.id === userId), [userId, userQuery.data]);

  const onDeleteUserSuccess = () => {
    setIsDeleteDialogOpen(false);
    void navigate({
      search: {},
    });
  };

  const onOpenChange = (value: boolean) => {
    setIsDeleteDialogOpen(false);
    void navigate({
      search: value ? { userSidebarIntent: viewMode } : {},
    });
  };

  const { title, description } = VIEW_MODE_LABELS[viewMode];

  return (
    <Drawer
      title={title && t(title)}
      description={description && t(description)}
      mode="modal"
      side="right"
      size="xLarge"
      insetContent
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      actions={
        viewMode !== 'create' && (
          <Authorize
            policy={{
              scope: 'user:write',
            }}
          >
            <Button size="1" color="red" onClick={() => setIsDeleteDialogOpen(true)}>
              {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.delete_user')}
            </Button>
          </Authorize>
        )
      }
    >
      <div className="mt-3 h-full px-4 py-2">
        {isDeleteDialogOpen && (
          <DeleteUserInlineDialog
            userId={userId}
            onCancel={() => setIsDeleteDialogOpen(false)}
            onSuccess={onDeleteUserSuccess}
          />
        )}

        <Content
          isLoading={userQuery.isLoading}
          isError={userQuery.isError}
          viewMode={viewMode}
          user={user}
          onSuccess={onSuccess}
        />
      </div>
    </Drawer>
  );
};
