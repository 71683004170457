import * as Sentry from '@sentry/react';

function getEnvironment() {
  const isDevBuild = ['ui-dev', 'qa-', 'tv-', 'ch-', 'aa-', 'mp-', 'ak-', 'bl-', 'tm-', 'staging-'].some((item) =>
    window.location.hostname.startsWith(item),
  );

  if (isDevBuild) {
    return 'development';
  }

  return import.meta.env.MODE;
}

export const initializeSentry = () => {
  const shouldInitSentry = import.meta.env.VITE_DEPLOYMENT_TYPE !== 'enterprise' && import.meta.env.PROD;

  if (!shouldInitSentry) {
    return;
  }

  Sentry.init({
    dsn: 'https://247d7621a1f7ea14eb28a46a9a09262c@o228377.ingest.us.sentry.io/4507130971488256',
    environment: getEnvironment(),
    release: `${import.meta.env.VITE_RELEASE_VERSION}`,
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.4,
    autoSessionTracking: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'Non-Error promise rejection', // seems like outlook safe-links spam
    ],
  });
};
