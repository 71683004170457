import * as z from 'zod';
import { createFileRoute } from '@tanstack/react-router';

import { Pipelines } from 'components/scenes/Settings/Platform/Pipelines';

const pipelineSchema = z.union([
  z.object({}),
  z.object({
    pipelineSidebarIntent: z.literal('create'),
  }),
  z.object({
    pipelineSidebarIntent: z.literal('edit'),
    pipelineId: z.string(),
  }),
]);

export const Route = createFileRoute('/_app/settings/platform/pipelines')({
  component: Pipelines,
  validateSearch: pipelineSchema,
});
