import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { paths } from 'types/schemas/api-schema';

import { api, type APIError } from '.';
import { useGlobalRoles, useTenantRoles } from './roles';

export const useTenants = () => {
  return useQuery({
    queryKey: ['tenants'],
    queryFn: () => {
      return api
        .url('/tenants')
        .get()
        .json<paths['/tenants']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useTenant = (tenantId?: string) => {
  return useQuery({
    queryKey: ['tenants', tenantId],
    queryFn: () => {
      return api
        .url(`/tenants/${tenantId}`)
        .get()
        .json<paths['/tenants/{tenantId}']['get']['responses']['200']['content']['application/json']>();
    },
    enabled: !!tenantId,
  });
};

type CreateTenantResponse = paths['/tenants']['post']['responses']['201']['content']['application/json'];

export const useCreateTenant = () => {
  const queryClient = useQueryClient();

  return useMutation<
    CreateTenantResponse,
    APIError<paths['/tenants']['post']['responses']['400']['content']['application/json']>,
    paths['/tenants']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (tenant) => {
      return api.url('/tenants').post(tenant).json<CreateTenantResponse>();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants'] });
      await queryClient.invalidateQueries({ queryKey: ['roles'] });
      await queryClient.invalidateQueries({ queryKey: ['pipelines'] });
    },
  });
};

type EditTenantResponse = paths['/tenants/{tenantId}']['put']['responses']['200']['content']['application/json'];

export const useEditTenant = (tenantId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    EditTenantResponse,
    APIError<paths['/tenants/{tenantId}']['put']['responses']['400']['content']['application/json']>,
    paths['/tenants/{tenantId}']['put']['requestBody']['content']['application/json']
  >({
    mutationFn: async (data) => {
      const response = await api.url(`/tenants/${tenantId}`).put(data).json<EditTenantResponse>();

      await queryClient.invalidateQueries({
        queryKey: ['tenants'],
      });

      return response;
    },
  });
};

export const useTenantUsersWithRoles = (tenantId: string) => {
  const globalRolesQuery = useGlobalRoles();
  const tenantRolesQuery = useTenantRoles(tenantId);

  return useQuery({
    queryKey: ['tenants', tenantId, 'usersAccess'],
    enabled: globalRolesQuery.isSuccess && tenantRolesQuery.isSuccess,
    queryFn: async () => {
      const users = await api
        .get('/auth/users')
        .json((r: paths['/auth/users']['get']['responses']['200']['content']['application/json']) => r.data);

      const { globalAdminRole, globalAnalystRole } = globalRolesQuery.data!;
      const { adminRole, analystRole } = tenantRolesQuery.data!;

      const globalAdminUsers = users.filter((user) => user.roles.includes(globalAdminRole?.id || ''));
      const globalAnalystUsers = users.filter((user) => user.roles.includes(globalAnalystRole?.id || ''));
      const tenantAdminUsers = users.filter((user) => user.roles.includes(adminRole.id));
      const tenantAnalystUsers = users.filter((user) => user.roles.includes(analystRole.id));

      return users.map((user) => {
        let accessLevel: 'global' | 'tenant' | null;
        let role: 'admin' | 'analyst' | null;

        if (globalAdminUsers.includes(user)) {
          accessLevel = 'global';
          role = 'admin';
        } else if (globalAnalystUsers.includes(user)) {
          accessLevel = 'global';
          role = 'analyst';
        } else if (tenantAdminUsers.includes(user)) {
          accessLevel = 'tenant';
          role = 'admin';
        } else if (tenantAnalystUsers.includes(user)) {
          accessLevel = 'tenant';
          role = 'analyst';
        } else {
          role = null;
          accessLevel = null;
        }

        return {
          user,
          accessLevel,
          role,
        };
      });
    },
  });
};

export type UsersWithTenantAccess = NonNullable<ReturnType<typeof useTenantUsersWithRoles>['data']>;

export const useDeleteTenant = (tenantId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/tenants/{tenantId}']['delete']['responses']['204']['content'],
    APIError<paths['/tenants/{tenantId}']['delete']['responses']['401']['content']['application/json']>
  >({
    mutationFn: async () => {
      await api.url(`/tenants/${tenantId}`).delete().res();

      return undefined;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['tenants'],
      });
    },
  });
};
