import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from '@tanstack/react-router';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from 'services/i18n/useTranslate';
import { type components } from 'types/schemas/api-schema';
import { useCreateTenant, useEditTenant } from 'services/api/tenants';
import * as errorMessages from 'consts/errorMessages';

import { Button } from 'components/common/Button';
import { ClipboardIcon } from 'components/common/Icons';
import { TextField } from 'components/common/TextField';
import { Callout } from 'components/common/Callout';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormTooltip } from 'components/common/Form';

const TENANT_NAME_MIN_LENGTH = 6;

export const SettingsTabItem = () => {
  return <ClipboardIcon className="size-4" />;
};

const tenantSchema = z.object({
  name: z.string().min(6, errorMessages.minLength),
  description: z.string().optional(),
});

type TenantFormFields = z.infer<typeof tenantSchema>;

const SettingsForm = ({
  tenant,
  isLoading,
  error,
  onSubmit,
  onCloseCallout,
}: {
  tenant?: Partial<components['schemas']['tenant']>;
  isLoading?: boolean;
  error?: components['schemas']['errorResponse'];
  onSubmit: (data: TenantFormFields) => void;
  onCloseCallout?: () => void;
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const form = useForm<TenantFormFields>({
    resolver: zodResolver(tenantSchema),
    defaultValues: {
      name: tenant?.name || '',
      description: tenant?.description || '',
    },
  });

  const handleCancel = () => {
    void navigate({
      search: undefined,
    });
  };

  return (
    <Form {...form}>
      <form className="flex h-full w-full flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field, fieldState }) => {
            return (
              <FormItem>
                <FormLabel>{t('common.name')}</FormLabel>
                <FormTooltip content={t('settings.tabs.platform.subtabs.tenants.sidebar.name_info_tooltip')} />
                <FormControl>
                  <TextField {...field} />
                </FormControl>

                {fieldState.error && <FormMessage translationVars={{ min: TENANT_NAME_MIN_LENGTH }} />}
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>{t('common.description')}</FormLabel>
                <FormTooltip content={t('settings.tabs.platform.subtabs.tenants.sidebar.description_info_tooltip')} />
                <FormControl>
                  <TextField {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            );
          }}
        />

        <div className="flex-1" />

        {error && (
          <Callout status="error" onClose={onCloseCallout}>
            {error?.errors?.[0]?.message}
          </Callout>
        )}

        <div className="mb-2 flex justify-end gap-2" data-test-id="sidebar-action-buttons">
          <Button variant="soft" type="button" color="gray" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button isLoading={isLoading} type="submit">
            {t('common.save')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export type SettingsCreateTabContentProps = {
  onSuccess: (tenant: components['schemas']['tenant']) => void;
};

export const SettingsCreateTabContent = ({ onSuccess }: SettingsCreateTabContentProps) => {
  const navigate = useNavigate();
  const createTenantMutation = useCreateTenant();

  const handleSubmit = async (data: TenantFormFields) => {
    await createTenantMutation.mutateAsync(data, {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
    });

    void navigate({
      search: undefined,
    });
  };

  return (
    <SettingsForm
      isLoading={createTenantMutation.isPending}
      error={createTenantMutation.error?.json}
      onCloseCallout={createTenantMutation.reset}
      onSubmit={handleSubmit}
    />
  );
};

export const SettingsEditTabContent = ({
  tenant,
  onSuccess,
}: {
  tenant: components['schemas']['tenant'];
  onSuccess: (tenant: components['schemas']['tenant']) => void;
}) => {
  const navigate = useNavigate();
  const editTenantMutation = useEditTenant(tenant.id);

  const handleSubmit = async (data: TenantFormFields) => {
    const updatedTenant = await editTenantMutation.mutateAsync(data);

    void navigate({
      search: undefined,
    });

    onSuccess(updatedTenant.data);
  };

  return (
    <SettingsForm
      error={editTenantMutation.error?.json}
      onCloseCallout={editTenantMutation.reset}
      isLoading={editTenantMutation.isPending}
      tenant={tenant}
      onSubmit={handleSubmit}
    />
  );
};
