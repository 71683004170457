import { type ReactNode, type ComponentProps } from 'react';
import { Callout as RadixCallout } from '@radix-ui/themes';

import { IconButton } from 'components/common/IconButton';
import { ExclamationTriangleIcon, InfoCircledIcon, CheckCircledIcon, Cross2Icon } from 'components/common/Icons';
import { cn } from 'utils/styles';

export type CalloutStatus = 'success' | 'error' | 'info' | 'warning';
type CalloutColor = 'blue' | 'red' | 'green' | 'amber';

type CalloutProps = ComponentProps<typeof RadixCallout.Root> & {
  status: CalloutStatus;
  className?: string;
  onClose?: () => void;
  children: ReactNode;
};

const COLOR_MAP: Record<CalloutStatus, CalloutColor> = {
  success: 'green',
  error: 'red',
  info: 'blue',
  warning: 'amber',
};

const CalloutIcon = ({ status, className }: { status: CalloutStatus; className?: string }): ReactNode => {
  switch (status) {
    case 'info':
      return <InfoCircledIcon className={className} />;
    case 'error':
      return <ExclamationTriangleIcon className={className} />;
    case 'success':
      return <CheckCircledIcon className={className} />;
    case 'warning':
      return <ExclamationTriangleIcon className={className} />;
    default:
      return null;
  }
};

export const Callout = ({ status, className, size, onClose, children, ...props }: CalloutProps) => {
  const role = status === 'error' ? 'alert' : undefined;

  return (
    <RadixCallout.Root {...props} size={size} color={COLOR_MAP[status]} role={role} className={cn('!flex', className)}>
      <RadixCallout.Icon>
        <CalloutIcon
          status={status}
          className={cn({
            'size-4': size === '1' || size === '2',
            'size-6': size === '3',
          })}
        />
      </RadixCallout.Icon>
      <RadixCallout.Text className="flex-1">{children}</RadixCallout.Text>
      {onClose && (
        <IconButton type="button" size="1" variant="ghost" className={cn('!mt-0 !text-neutral-12')} onClick={onClose}>
          <Cross2Icon
            className={cn({
              'size-6': size === '3',
            })}
          />
        </IconButton>
      )}
    </RadixCallout.Root>
  );
};
