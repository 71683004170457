import React, { useCallback, useMemo, useState, type ReactNode } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { format } from 'date-fns';
import type { HeaderGroup, SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { cn } from 'utils/styles';
import { LOADING_ANOMALIES } from 'mocks/anomalies';
import { dateTimeFormatFull } from 'consts/dateFormats';
import type { components } from 'types/schemas/api-schema';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useAnomalies, useAnomalyParams } from 'services/api/anomalies';

import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Skeleton } from 'components/common/Skeleton';
import { ScrollArea } from 'components/common/ScrollArea';
import { TextAlignLeftIcon } from 'components/common/Icons';
import { PaginationControlPanel } from 'components/common/PaginationControlPanel';
import { SortableColumnHeaderCell, Table } from 'components/common/Table';

import {
  PipelineFilterBadge,
  TenantFilterBadge,
  EntitiesFilterBadge,
  DimensionsFilterBadge,
} from '../common/AnomaliesFilterBadges';
import { type AnomaliesFilterProperty, useAnomaliesContext } from '../AnomaliesContext';

type AnomalyData = components['schemas']['anomaly'];

const anomalyColumnHelper = createColumnHelper<AnomalyData>();
const DEFAULT_DATA: AnomalyData[] = [];

export const Container = ({ children }: { children: ReactNode }) => {
  return <div className="mt-3 p-3">{children}</div>;
};

export const AnomaliesTable = () => {
  const t = useTranslate();
  const anomalyParams = useAnomalyParams();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { addFilter, pagination, setPagination } = useAnomaliesContext();

  const handlePageSizeChange = (value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: Number(value),
    });
  };

  const anomaliesQuery = useAnomalies({
    ...anomalyParams,
    start: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
  });

  const tenantsQueryData = useTenants();
  const pipelinesQueryData = usePipelines();
  const totalCount = anomaliesQuery.data?.metadata.totalCount;

  const getIsFilterActive = useCallback(
    (property: AnomaliesFilterProperty, value: string) => {
      const activeValues = anomalyParams[property];

      if (!activeValues?.length) {
        return false;
      }

      return activeValues.includes(value);
    },
    [anomalyParams],
  );

  const handleOpenAnomaly = useCallback(
    (anomalyId: string) => {
      void navigate({
        search: (prev) => ({
          ...prev,
          anomalyId: anomalyId,
        }),
      });
    },
    [navigate],
  );

  const isLoading = anomaliesQuery.isLoading || tenantsQueryData.isLoading || pipelinesQueryData.isLoading;

  const columns = useMemo(() => {
    return [
      anomalyColumnHelper.accessor('confidenceScore', {
        enableSorting: false,
        header: () => <Text className="truncate">{t('common.confidence_score')}</Text>,
        cell: (info) => (
          <Text as="div" weight="medium" className="w-full text-center text-gray-11">
            <Skeleton isLoading={isLoading}>{info.getValue()}</Skeleton>
          </Text>
        ),
      }),
      anomalyColumnHelper.accessor('observationWindow.end', {
        enableSorting: false,
        header: () => <Text>{t('anomalies.table.headers.time')}</Text>,
        cell: (info) => (
          <Text className="truncate whitespace-nowrap text-gray-a11">
            <Skeleton isLoading={isLoading}>{format(new Date(info.getValue()), dateTimeFormatFull)}</Skeleton>
          </Text>
        ),
      }),
      anomalyColumnHelper.accessor('pipelineId', {
        enableSorting: false,
        header: () => <Text>{t('common.pipeline')}</Text>,
        cell: (info) => {
          const pipeline = pipelinesQueryData.data?.data.find((p) => p.id === info.getValue());
          return (
            <Skeleton isLoading={isLoading}>
              <PipelineFilterBadge
                children={pipeline?.name ?? info.getValue()}
                isActive={getIsFilterActive('pipelineId', info.getValue())}
                onClick={() => addFilter('pipelineId', info.getValue())}
              />
            </Skeleton>
          );
        },
      }),
      anomalyColumnHelper.accessor('entities', {
        enableSorting: false,
        header: () => <Text>{t('common.entities')}</Text>,
        cell: (info) => (
          <div className="flex flex-wrap items-start gap-1">
            {(info.getValue() || []).map((entity) => (
              <Skeleton key={entity.value} isLoading={isLoading}>
                <EntitiesFilterBadge
                  children={entity.value}
                  isActive={getIsFilterActive('entity', entity.value)}
                  onClick={() => addFilter('entity', entity.value)}
                />
              </Skeleton>
            ))}
          </div>
        ),
      }),
      anomalyColumnHelper.accessor('tenantId', {
        enableSorting: false,
        header: () => <Text className="w-full text-right">{t('common.tenant')}</Text>,
        cell: (info) => {
          const tenant = tenantsQueryData.data?.data.find((t) => t.id === info.getValue());
          return (
            <div className="pr-2 text-right">
              <Skeleton isLoading={isLoading}>
                <TenantFilterBadge
                  children={tenant?.name ?? info.getValue()}
                  isActive={getIsFilterActive('tenantId', info.getValue())}
                  onClick={() => addFilter('tenantId', info.getValue())}
                />
              </Skeleton>
            </div>
          );
        },
      }),
      anomalyColumnHelper.accessor('id', {
        enableSorting: false,
        header: () => <Text className="w-full pr-2 text-right">{t('common.details')}</Text>,
        cell: (info) => {
          return (
            <div className="flex items-center justify-end pr-2">
              <Skeleton isLoading={isLoading}>
                <Button size="1" variant="soft" onClick={() => handleOpenAnomaly(info.getValue())}>
                  <TextAlignLeftIcon /> {t('common.view')}
                </Button>
              </Skeleton>
            </div>
          );
        },
      }),
    ];
  }, [
    getIsFilterActive,
    addFilter,
    handleOpenAnomaly,
    isLoading,
    pipelinesQueryData.data?.data,
    t,
    tenantsQueryData.data?.data,
  ]);

  const table = useReactTable({
    data: isLoading ? LOADING_ANOMALIES : (anomaliesQuery.data?.data ?? DEFAULT_DATA),
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    rowCount: pagination.pageSize,
    manualPagination: true,
  });

  if (anomaliesQuery.error || tenantsQueryData.error || pipelinesQueryData.error) {
    return <Container>{t('anomalies.table.errors.loading_anomalies')}</Container>;
  }

  return (
    <div className="relative">
      <ScrollArea style={{ height: 'calc(100vh - 242px)' }}>
        <Table.Root className="mb-12 bg-neutral-2" size="1" data-test-id="anomalies-table">
          <Table.Header>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<AnomalyData>) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <SortableColumnHeaderCell key={header.id} header={header} />
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <Table.Row align="center">
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      rowSpan={cell.column.id === 'confidenceScore' ? 2 : 1}
                      className={cn(cell.column.id !== 'confidenceScore' && '!shadow-none')}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.Cell>
                  ))}
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={row.getVisibleCells().length}>
                    <div className="flex flex-wrap items-center gap-1">
                      {row.original.dimensions.map((dim) => {
                        if (!dim.values?.length) {
                          return null;
                        }

                        return dim.values.map((val) => {
                          const fullValue = `${dim.name}:${val.value}`;

                          return (
                            <Skeleton key={fullValue} isLoading={isLoading}>
                              <DimensionsFilterBadge
                                children={fullValue}
                                isActive={getIsFilterActive('dimension', fullValue)}
                                onClick={() => addFilter('dimension', fullValue)}
                              />
                            </Skeleton>
                          );
                        });
                      })}
                    </div>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table.Root>
      </ScrollArea>

      <PaginationControlPanel
        pageSize={pagination.pageSize}
        onPageSizeChange={handlePageSizeChange}
        canPrevious={table.getCanPreviousPage()}
        canNext={anomaliesQuery.data?.metadata.hasMore ?? false}
        onPrevious={() => table.previousPage()}
        onNext={() => table.nextPage()}
        isLoading={anomaliesQuery.isLoading}
        isFetching={anomaliesQuery.isFetching}
        totalCount={totalCount!}
        pageIndex={pagination.pageIndex}
      />
    </div>
  );
};
