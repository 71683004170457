import { useCallback, useState } from 'react';
import { format } from 'date-fns';

import { cn } from 'utils/styles';
import type { DateTimeFilter, ValidTimerange } from 'consts/dateTime';
import { dateTimeFormatShort } from 'consts/dateFormats';
import { useTranslate } from 'services/i18n/useTranslate';
import type { FlattenedTranslationKeys } from 'types/translations';

import { DateTimePicker } from 'components/common/DateTimePicker';
import { Text } from 'components/common/Text';
import { Badge } from 'components/common/Badge';
import { Popover } from 'components/common/Popover';
import { CalendarIcon, TriangleDownIcon } from 'components/common/Icons';

type RangeOption = {
  label: FlattenedTranslationKeys;
  value: ValidTimerange;
  shortLabel: string;
};

type DateTimePickerProps = {
  rangeOptions: RangeOption[];
  startTime?: string;
  endTime?: string;
  timeRange?: ValidTimerange;
  onChange: (data: DateTimeFilter) => void;
};

const DateOption = ({
  label,
  selected,
  badgeContent,
  onClick,
}: {
  label: FlattenedTranslationKeys;
  selected: boolean;
  onClick: () => void;
  badgeContent: React.ReactNode;
}) => {
  return (
    <button
      className={cn(
        `flex items-center gap-2 py-0.5 pl-0.5 focus:outline-accent-9`,
        selected ? 'bg-neutral-3' : 'hover:bg-neutral-3',
      )}
      onClick={onClick}
    >
      <Badge
        size="1"
        variant="soft"
        color="sky"
        className="flex h-5 w-[50px] !cursor-pointer items-center justify-center"
      >
        {badgeContent}
      </Badge>
      <Text size="2" className="text-neutral-12">
        {label}
      </Text>
    </button>
  );
};

export const PresetDateTimePicker = ({
  rangeOptions,
  startTime,
  endTime,
  timeRange,
  onChange,
}: DateTimePickerProps) => {
  const t = useTranslate();

  const selectedValue = timeRange || 'custom';
  const selectedOption = rangeOptions.find((option) => option.value === selectedValue) || {
    label: 'common.date_picker.custom',
    value: 'custom',
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCustomViewOpen, setIsCustomViewOpen] = useState(false);

  const from = startTime ? new Date(startTime) : undefined;
  const to = endTime ? new Date(endTime) : undefined;

  const handleRangeChange = useCallback(
    (range: { from: Date; to: Date }) => {
      onChange({
        startTime: range.from.toISOString(),
        endTime: range.to.toISOString(),
        timeRange: undefined,
      });
      setIsPopoverOpen(false);
      setIsCustomViewOpen(false);
    },
    [onChange],
  );

  const handleOptionClick = (value: (typeof rangeOptions)[number]['value'] | 'custom') => {
    if (value === 'custom') {
      setIsCustomViewOpen(true);
      setIsPopoverOpen(true);
    } else {
      setIsPopoverOpen(false);

      onChange({
        timeRange: value,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };

  const onPopoverValueChange = (value: boolean) => {
    setIsCustomViewOpen(false);
    setIsPopoverOpen(value);
  };

  const formatCustomRange = (from: Date, to: Date) => {
    return `${format(from, dateTimeFormatShort)} - ${format(to, dateTimeFormatShort)}`;
  };

  return (
    <div
      className="m-2 flex h-8 flex-col rounded-sm border border-neutral-a7 bg-surface"
      data-test-id="preset-datetime-picker"
    >
      <Popover.Root open={isPopoverOpen} onOpenChange={onPopoverValueChange}>
        <Popover.Trigger>
          <button
            className="relative flex h-full items-center p-1 hover:bg-neutral-3 data-[state=open]:bg-neutral-a3 dark:bg-neutral-2"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <div className="flex gap-2">
              {selectedOption.value !== 'custom' && (
                <Badge
                  size="1"
                  variant="soft"
                  color="sky"
                  className="flex h-5 w-[50px] !cursor-pointer items-center justify-center"
                >
                  {selectedOption.shortLabel}
                </Badge>
              )}
              <Text size="2" className="flex overflow-hidden text-ellipsis whitespace-nowrap pl-0.5 text-neutral-12">
                {selectedOption.value === 'custom' ? formatCustomRange(from!, to!) : t(selectedOption.label)}
              </Text>
            </div>

            <TriangleDownIcon className="absolute right-1 text-neutral-12" />
          </button>
        </Popover.Trigger>
        <Popover.Content
          data-test-id="preset-datetime-picker-popover"
          className={cn(
            isCustomViewOpen ? 'mt-[-7px] w-[282px]' : '-ml-[1px] -mt-[39px] w-[284px]',
            'flex flex-col gap-1 !rounded-sm !p-1',
          )}
        >
          {isCustomViewOpen ? (
            <DateTimePicker from={from} to={to} onChange={handleRangeChange} />
          ) : (
            <>
              {rangeOptions.map((option, index) => (
                <DateOption
                  key={index}
                  label={t(option.label)}
                  selected={selectedValue === option.value}
                  onClick={() => handleOptionClick(option.value)}
                  badgeContent={option.shortLabel}
                />
              ))}

              <DateOption
                label={t('common.date_picker.custom')}
                selected={selectedValue === 'custom'}
                badgeContent={<CalendarIcon className="size-3" />}
                onClick={() => setIsCustomViewOpen(true)}
              />
            </>
          )}
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};
