import { useState, type ChangeEvent } from 'react';
import { MinusCircledIcon, MixerHorizontalIcon, PlusCircledIcon } from 'components/common/Icons';

import { useTenant } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useAssociatedEnrichmentsAndPipelines, useUpdateCustomPipelineEnrichments } from 'services/api/enrichments';
import type { components } from 'types/schemas/api-schema';

import { TextField } from 'components/common/TextField';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import type { APIError } from 'services/api';

type Pipeline = components['schemas']['pipelineView'];

type EnrichmentDataSet = components['schemas']['customEnrichmentSetMetadata'];
type PipelineData = components['schemas']['pipeline'];

export const AssignPipelineTabItem = () => {
  return <MixerHorizontalIcon className="size-4" />;
};

const PIPELINES_EMPTY_ARRAY: PipelineData[] = [];

type AssignmentButtonProps = {
  pipelineId: string;
  enrichmentId: string;
  tenantId: string;
  isDisabled?: boolean;
  onError: (error: any) => void;
};

const AssignEnrichmentButton = ({ pipelineId, enrichmentId, tenantId, onError }: AssignmentButtonProps) => {
  const t = useTranslate();
  const updatePipelineEnrichmentsQuery = useUpdateCustomPipelineEnrichments();

  return (
    <Button
      variant="soft"
      isLoading={updatePipelineEnrichmentsQuery.isPending}
      onClick={() =>
        updatePipelineEnrichmentsQuery.mutate(
          {
            pipelineId,
            customEnrichmentId: enrichmentId,
            tenantId,
          },
          {
            onError,
          },
        )
      }
    >
      <PlusCircledIcon className="size-4" aria-hidden /> {t('common.add')}
    </Button>
  );
};

const UnassignEnrichmentButton = ({
  pipelineId,
  tenantId,
  onError,
  isDisabled,
}: Omit<AssignmentButtonProps, 'enrichmentId'>) => {
  const t = useTranslate();
  const updatePipelineEnrichmentsQuery = useUpdateCustomPipelineEnrichments();

  return (
    <Button
      variant="soft"
      isLoading={updatePipelineEnrichmentsQuery.isPending}
      disabled={isDisabled}
      onClick={() =>
        updatePipelineEnrichmentsQuery.mutate(
          {
            pipelineId,
            customEnrichmentId: null,
            tenantId,
          },
          {
            onError,
          },
        )
      }
    >
      <MinusCircledIcon className="size-4" aria-hidden /> {t('common.remove')}
    </Button>
  );
};

export const AssignPipelineContent = ({ enrichment }: { enrichment: EnrichmentDataSet }) => {
  const t = useTranslate();
  const tenantQuery = useTenant(enrichment.tenantId);
  const pipelinesQuery = usePipelines(enrichment.tenantId);
  const [searchTerm, setSearchTerm] = useState('');
  const [assignmentError, setAssignmentError] = useState<APIError<components['responses']['standardError']> | null>(
    null,
  );

  const pipelineEnrichmentsQuery = useAssociatedEnrichmentsAndPipelines(enrichment.tenantId);

  // mapping to make sure the response types are the same
  const pipelineList = (pipelinesQuery.data?.data || PIPELINES_EMPTY_ARRAY).map((p) => ({ id: p.id, name: p.name! }));

  const assignedPipelines: Pipeline[] =
    pipelineEnrichmentsQuery?.data?.data?.flatMap((set) => (set.id === enrichment.id ? set.pipelines : [])) || [];

  const pipelinesAssignedToOtherEnrichments: Pipeline[] =
    pipelineEnrichmentsQuery?.data?.data?.flatMap((set) => (set.id !== enrichment.id ? set.pipelines : [])) || [];

  const unassignedPipelines = pipelineList.filter(
    (pipeline) =>
      !assignedPipelines.some((assigned) => assigned.id === pipeline.id) &&
      !pipelinesAssignedToOtherEnrichments.some((assignedToOther) => assignedToOther.id === pipeline.id),
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filterPipelinesBySearchTerm = (pipelines: Pipeline[]) => {
    if (!searchTerm) {
      return pipelines;
    }

    return pipelines.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const tenant = tenantQuery?.data?.data;

  const allAssignedPipelines = assignedPipelines.concat(pipelinesAssignedToOtherEnrichments);

  const filteredAssignedPipelines = filterPipelinesBySearchTerm(allAssignedPipelines);
  const filteredUnassignedPipelines = filterPipelinesBySearchTerm(unassignedPipelines);

  return (
    <div className="flex h-full flex-col gap-3">
      <div className="mt-3 flex flex-col gap-8">
        <TextField placeholder={t('common.find')} value={searchTerm} onChange={handleSearch} />

        <div>
          {!!allAssignedPipelines.length && (
            <Text weight="medium">
              {t('settings.tabs.enrichments.subtabs.manage.sidebar.assigned_pipeline', { tenantName: tenant?.name })}
            </Text>
          )}
          <ul>
            {filteredAssignedPipelines.length > 0 ? (
              filteredAssignedPipelines.map((pipeline) => {
                const isDisabled = pipelinesAssignedToOtherEnrichments.some((p) => p.id === pipeline.id);
                return (
                  <li key={pipeline.id} className="my-2 flex items-center justify-between space-x-1">
                    <div className="flex h-8 w-full items-center rounded-2 bg-neutral-1 px-3 drop-shadow-sm">
                      {pipeline?.name}
                    </div>
                    <UnassignEnrichmentButton
                      pipelineId={pipeline.id}
                      tenantId={enrichment.tenantId}
                      onError={setAssignmentError}
                      isDisabled={isDisabled}
                    />
                  </li>
                );
              })
            ) : (
              <Text>{t('settings.tabs.enrichments.subtabs.manage.sidebar.no_pipelines_found')}</Text>
            )}
          </ul>
        </div>

        <div>
          {!!unassignedPipelines.length && (
            <Text weight="medium">
              {t('settings.tabs.enrichments.subtabs.manage.sidebar.unassigned_pipeline', { tenantName: tenant?.name })}
            </Text>
          )}
          <ul>
            {filteredUnassignedPipelines.length > 0 ? (
              filteredUnassignedPipelines.map((pipeline) => (
                <li key={pipeline.id} className="my-2 flex items-center justify-between space-x-1">
                  <div className="flex h-8 w-full items-center rounded-2 bg-neutral-1 px-3 drop-shadow-sm">
                    {pipeline?.name}
                  </div>
                  <AssignEnrichmentButton
                    pipelineId={pipeline.id}
                    enrichmentId={enrichment.id}
                    tenantId={enrichment.tenantId}
                    onError={setAssignmentError}
                  />
                </li>
              ))
            ) : (
              <Text>{t('settings.tabs.enrichments.subtabs.manage.sidebar.no_pipelines_found')}</Text>
            )}
          </ul>
        </div>
      </div>

      {assignmentError && (
        <Callout status="error" onClose={() => setAssignmentError(null)}>
          {assignmentError.message}
        </Callout>
      )}
    </div>
  );
};
