import { useEffect } from 'react';
import { getRouteApi } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';

import { BASE_RANGE_OPTIONS } from 'consts/dateTime';
import { PresetDateTimePicker } from 'components/common/PresetDateTimePicker';
import { isValidTimeRange } from 'utils/dateTimeValidation';
import { resetRelativeQueriesPredicate } from 'utils/detections';

import { Facets } from './Facets';
import { Histogram } from './Histogram';
import { AnomaliesTable } from './Table';
import { FilterBar } from './FilterBar';
import { AnomalySidebar } from './AnomalySidebar';
import { AnomaliesProvider, useAnomaliesContext } from './AnomaliesContext';

const routeApi = getRouteApi('/_app/anomalies');

const AnomaliesSceneContent = () => {
  const routeSearch = routeApi.useSearch();
  const queryClient = useQueryClient();
  const { changeTimerange } = useAnomaliesContext();

  const timeRange = isValidTimeRange(routeSearch.timeRange) ? routeSearch.timeRange : undefined;

  const openAnomalyId = 'anomalyId' in routeSearch ? (routeSearch.anomalyId as string) : undefined;

  useEffect(() => {
    void queryClient.removeQueries({
      predicate: (query) => {
        return resetRelativeQueriesPredicate('anomalies', timeRange, query.queryKey);
      },
    });
  }, [queryClient, timeRange]);

  return (
    <div className="flex bg-neutral-3">
      <AnomalySidebar anomalyId={openAnomalyId} />

      <div className="flex w-[300px] flex-col gap-1">
        <PresetDateTimePicker
          rangeOptions={BASE_RANGE_OPTIONS}
          startTime={routeSearch.startTime}
          endTime={routeSearch.endTime}
          timeRange={timeRange}
          onChange={changeTimerange}
        />
        <Facets />
      </div>
      <div className="w-[calc(100%-300px)]">
        <FilterBar />
        <Histogram />
        <AnomaliesTable />
      </div>
    </div>
  );
};

export const AnomaliesScene = () => {
  return (
    <AnomaliesProvider>
      <AnomaliesSceneContent />
    </AnomaliesProvider>
  );
};
