import _ from 'lodash';
import type { QueryKey } from '@tanstack/react-query';

import type { FacetSectionProps } from 'components/common/FacetSection';
import type { components } from 'types/schemas/api-schema';
import type { DateTimeFilter } from 'consts/dateTime';
import { HIGH_RISK_SCORES, LOW_RISK_SCORES, MEDIUM_RISK_SCORES } from 'consts/threats';
import { HIGH_CONFIDENCE_SCORES, LOW_CONFIDENCE_SCORES, MEDIUM_CONFIDENCE_SCORES } from 'consts/anomalies';

export function getRangeForScore(
  score: number,
  highScores: number[],
  mediumScores: number[],
  lowScores: number[],
): 'high' | 'medium' | 'low' {
  if (highScores.includes(score)) {
    return 'high';
  } else if (mediumScores.includes(score)) {
    return 'medium';
  } else if (lowScores.includes(score)) {
    return 'low';
  }

  return 'low';
}

export function getRiskScoreRange(score: number): 'high' | 'medium' | 'low' {
  return getRangeForScore(score, HIGH_RISK_SCORES, MEDIUM_RISK_SCORES, LOW_RISK_SCORES);
}

export function groupScoresToRanges<T>(
  values: T[],
  highScores: number[],
  mediumScores: number[],
  lowScores: number[],
  scoreGetter: (value: T) => number = (x) => x as number,
  countGetter: (score: T) => number = () => 1,
): {
  high: number;
  medium: number;
  low: number;
} {
  return values.reduce(
    (acc, value) => {
      const count = countGetter(value);
      const score = scoreGetter(value);

      const range = getRangeForScore(score, highScores, mediumScores, lowScores);

      acc[range] += count;

      return acc;
    },
    {
      high: 0,
      medium: 0,
      low: 0,
    },
  );
}

export const groupFacetScores = (
  facetsData: components['schemas']['detectionsFacet'][],
  highScores: number[],
  mediumScores: number[],
  lowScores: number[],
): Record<'high' | 'medium' | 'low', Omit<FacetSectionProps['options'][number], 'label'>> => {
  const ranges = groupScoresToRanges(
    facetsData,
    highScores,
    mediumScores,
    lowScores,
    (f) => parseInt(f.value, 10),
    (f) => f.count,
  );

  const totalCount = ranges.high + ranges.medium + ranges.low;

  return {
    high: {
      count: ranges.high,
      value: 'high',
      percentage: Math.round((ranges.high / totalCount) * 100) || 0,
    },
    medium: {
      count: ranges.medium,
      value: 'medium',
      percentage: Math.round((ranges.medium / totalCount) * 100) || 0,
    },
    low: {
      count: ranges.low,
      value: 'low',
      percentage: Math.round((ranges.low / totalCount) * 100) || 0,
    },
  };
};

export const groupFacetConfidenceScores = (facetsData: components['schemas']['detectionsFacet'][]) => {
  return groupFacetScores(facetsData, HIGH_CONFIDENCE_SCORES, MEDIUM_CONFIDENCE_SCORES, LOW_CONFIDENCE_SCORES);
};

export const groupFacetRiskScores = (facetsData: components['schemas']['detectionsFacet'][]) => {
  return groupFacetScores(facetsData, HIGH_RISK_SCORES, MEDIUM_RISK_SCORES, LOW_RISK_SCORES);
};

export const getTenantFacetOptions = (
  facetsData: components['schemas']['detectionsFacet'][],
  tenants: components['schemas']['tenant'][],
): FacetSectionProps['options'] => {
  const data = facetsData.map((c) => {
    const tenant = tenants.find((t) => t.id === c.value);
    return {
      label: tenant?.name || c.value,
      value: c.value,
      count: c.count,
      percentage: c.percentage,
    };
  });

  return data;
};

export const getPipelinesFacetOptions = (
  facetsData: components['schemas']['detectionsFacet'][],
  tenants: components['schemas']['pipeline'][],
): FacetSectionProps['options'] => {
  const data = facetsData.map((c) => {
    const pipeline = tenants.find((t) => t.id === c.value);
    return {
      label: pipeline?.name || c.value,
      value: c.value,
      count: c.count,
      percentage: c.percentage,
    };
  });

  return data;
};

export function extractEntitiesFromThreat(threat: components['schemas']['threat']) {
  const entitiesFromPerceptions = threat.evidence.flatMap((e) =>
    e.observations.flatMap((o) => o.perceptions.flatMap((p) => (p.entities || []).map((e) => e))),
  );

  return _.uniqBy(entitiesFromPerceptions, 'value');
}

export type ParamsWithTimerange<T extends { startTime: string; endTime: string }> = T & { timeRange?: string };

export function mapParamsToQueryKey<T extends { startTime: string; endTime: string }>({
  startTime,
  endTime,
  timeRange,
  ...params
}: ParamsWithTimerange<T>) {
  if (timeRange) {
    return {
      ...params,
      timeRange,
    };
  }

  return {
    ...params,
    startTime,
    endTime,
  };
}

export function mapParamsToAPIParams<T extends { startTime: string; endTime: string }>({
  startTime,
  endTime,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timeRange,
  ...params
}: ParamsWithTimerange<T>) {
  return {
    ...params,
    startTime,
    endTime,
  };
}

export function resetRelativeQueriesPredicate(id: string, timeRange: string | undefined, queryKey: QueryKey) {
  if (queryKey?.[0] !== id) {
    return false;
  }

  let params = {} as DateTimeFilter;

  if (typeof queryKey[queryKey.length - 1] === 'object') {
    params = queryKey[queryKey.length - 1] as DateTimeFilter;
  }

  // if there is a switch from relative to absolute, clear all relative queries
  if ('timeRange' in params && !timeRange) {
    return true;
  }

  // if theres a switch from relative to relative with a different range, clear all other relative queries
  if ('timeRange' in params && timeRange !== params.timeRange) {
    return true;
  }

  return false;
}
