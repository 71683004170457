import * as z from 'zod';
import { createFileRoute } from '@tanstack/react-router';

import { SetupPasswordErrorComponent, SetupPasswordScene } from 'components/scenes/SetupPassword';

export const Route = createFileRoute('/_auth/create-account')({
  component: CreateAccount,
  errorComponent: SetupPasswordErrorComponent,
  validateSearch: z.object({
    token: z.string(),
  }),
  meta: () => [{ name: 'metaTitle', content: 'create_account.page_title' }],
});

function CreateAccount() {
  const navigate = Route.useNavigate();
  const { token } = Route.useSearch();

  return (
    <SetupPasswordScene
      token={token}
      title="create_account.page_title"
      onSuccess={(email) => {
        void navigate({
          to: '/',
          state: {
            data: {
              id: 'accountCreated',
              email,
            },
          },
        });
      }}
    />
  );
}
