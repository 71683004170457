import { getRouteApi } from '@tanstack/react-router';

const routeApi = getRouteApi('/_app/settings/users-and-access/users');

export type ViewMode = 'create' | 'edit' | 'view';
type SearchParams = {
  userSidebarIntent?: ViewMode;
  userId?: string;
};

export const useSearchParams = (): SearchParams => {
  return routeApi.useSearch();
};
