import { getRouteApi } from '@tanstack/react-router';

import { cn } from 'utils/styles';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useThreatsParams } from 'services/api/threats';

import { Skeleton } from 'components/common/Skeleton';
import { Badge } from 'components/common/Badge';
import { Cross1Icon, LayersIcon, MixerHorizontalIcon } from 'components/common/Icons';

import {
  ThreatDimensionBadge,
  ThreatEntityBadge,
  ThreatGeoBadge,
  ThreatRiskScoreBadge,
  ThreatStatusBadge,
} from '../common/ThreatsBadges';
import { useThreatsContext } from '../ThreatsContext';
import { useGetDisplayValue } from '../common/useGetDisplayValue';

const Route = getRouteApi('/_app/threats');

export const FilterBar = () => {
  const searchParams = Route.useSearch();
  const threatsParams = useThreatsParams();
  const tenantsQuery = useTenants();
  const pipelinesQuery = usePipelines();
  const { toggleFilter } = useThreatsContext();

  const getDisplayValue = useGetDisplayValue();

  const isLoading = tenantsQuery.isLoading || pipelinesQuery.isLoading;

  const combinedParams = [
    ...(threatsParams.riskScore || []),
    ...(threatsParams.status || []),
    ...(threatsParams.entity || []),
    ...(threatsParams.dimension || []),
    ...(threatsParams.pipelineId || []),
    ...(threatsParams.tenantId || []),
    ...(threatsParams.geo || []),
  ];

  const hasActiveFilters = !!combinedParams.length;

  return (
    <div
      className={cn(
        'my-2 mr-4 flex min-h-8 flex-wrap gap-2 rounded-1 px-2 py-1',
        hasActiveFilters ? 'border border-neutral-a7 bg-accent-a1' : 'border-none bg-transparent',
      )}
      data-test-id="threats-filter-bar"
    >
      {searchParams.riskScore?.map((value) => (
        <ThreatRiskScoreBadge
          key={value}
          isActive
          riskScoreRange={value}
          onClick={() => toggleFilter('riskScore', value)}
        />
      ))}

      {threatsParams.status?.map((value) => (
        <ThreatStatusBadge key={value} isActive status={value} onClick={() => toggleFilter('status', value)} />
      ))}

      {threatsParams.entity?.map((value) => (
        <ThreatEntityBadge key={value} isActive onClick={() => toggleFilter('entity', value)} entity={{ value }} />
      ))}

      {threatsParams.dimension?.map((value) => (
        <ThreatDimensionBadge key={value} isActive onClick={() => toggleFilter('dimension', value)}>
          {value}
        </ThreatDimensionBadge>
      ))}

      {threatsParams.pipelineId?.map((value) => (
        <Skeleton key={value} isLoading={isLoading}>
          <Badge
            onClick={() => toggleFilter('pipelineId', value)}
            className={'hover:cursor-pointer hover:bg-brown-6'}
            color="brown"
          >
            <MixerHorizontalIcon />
            {getDisplayValue('pipelineId', value)}
            <Cross1Icon className="h-3 w-3" />
          </Badge>
        </Skeleton>
      ))}

      {threatsParams.tenantId?.map((value) => (
        <Skeleton key={value} isLoading={isLoading}>
          <Badge
            onClick={() => toggleFilter('tenantId', value)}
            className={'hover:cursor-pointer hover:bg-gray-6'}
            color="gray"
          >
            <LayersIcon />
            {getDisplayValue('tenantId', value)}
            <Cross1Icon className="h-3 w-3" />
          </Badge>
        </Skeleton>
      ))}

      {threatsParams.geo?.map((value) => (
        <Skeleton key={value} isLoading={isLoading}>
          <ThreatGeoBadge geo={value} isActive onClick={() => toggleFilter('geo', value)} />
        </Skeleton>
      ))}
    </div>
  );
};
