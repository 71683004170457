import { useCallback } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { useNavigate } from '@tanstack/react-router';

import { useTenant } from 'services/api/tenants';
import { usePipeline } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useAnomaly, useAnomalyParams } from 'services/api/anomalies';
import { dateTimeWithTimezone } from 'consts/dateFormats';

import { Label } from 'components/common/Label';
import { Badge } from 'components/common/Badge';
import { Drawer } from 'components/common/Drawer';
import { Callout } from 'components/common/Callout';
import { Skeleton } from 'components/common/Skeleton';
import { TextField } from 'components/common/TextField';

import { DimensionsFilterBadge, EntitiesFilterBadge } from './common/AnomaliesFilterBadges';
import { type AnomaliesFilterProperty, useAnomaliesContext } from './AnomaliesContext';

const FieldLabel = ({ children }: { children: React.ReactNode }) => {
  return <Label className="!mb-1.5 block">{children}</Label>;
};

type AnomalySidebarProps = {
  anomalyId: string | undefined;
};

export const AnomalySidebar = ({ anomalyId }: AnomalySidebarProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const anomalyParams = useAnomalyParams();
  const anomalyDetailsQuery = useAnomaly(anomalyId);
  const tenantDetailsQuery = useTenant(anomalyDetailsQuery.data?.data?.tenantId);
  const pipelineDetailsQuery = usePipeline(anomalyDetailsQuery.data?.data?.pipelineId);
  const { addFilter } = useAnomaliesContext();

  const isLoading = anomalyDetailsQuery.isLoading || tenantDetailsQuery.isLoading || pipelineDetailsQuery.isLoading;

  const handleOpenChange = () => {
    void navigate({
      search: (prev) => {
        return _.omit(prev, ['anomalyId']);
      },
    });
  };

  const getIsFilterActive = useCallback(
    (property: AnomaliesFilterProperty, value: string) => {
      const activeValues = anomalyParams[property];

      if (!activeValues?.length) {
        return false;
      }

      return activeValues.includes(value);
    },
    [anomalyParams],
  );

  return (
    <Drawer
      title={t('anomalies.details_sidebar.title')}
      mode="modal"
      side="right"
      isOpen={!!anomalyId}
      onOpenChange={handleOpenChange}
    >
      {anomalyDetailsQuery.isError ? (
        <Callout status="error">
          {anomalyDetailsQuery.error.json.errors?.[0]?.message || anomalyDetailsQuery.error.message}
        </Callout>
      ) : (
        <div className="space-y-3" data-test-id="anomaly-sidebar">
          <div>
            <FieldLabel>{t('anomalies.details_sidebar.id')}</FieldLabel>
            <Skeleton isLoading={isLoading}>
              <TextField value={anomalyId} readOnly data-test-id="anomaly-sidebar-id-field" />
            </Skeleton>
          </div>

          <div>
            <FieldLabel>{t('common.tenant')}</FieldLabel>
            <Skeleton isLoading={isLoading}>
              <TextField
                value={tenantDetailsQuery.data?.data.name || anomalyDetailsQuery.data?.data.tenantId}
                readOnly
                data-test-id="anomaly-sidebar-tenant-field"
              />
            </Skeleton>
          </div>

          <div>
            <FieldLabel>{t('common.pipeline')}</FieldLabel>
            <Skeleton isLoading={isLoading}>
              <TextField
                value={pipelineDetailsQuery.data?.data.name || anomalyDetailsQuery.data?.data.pipelineId}
                readOnly
                data-test-id="anomaly-sidebar-pipeline-field"
              />
            </Skeleton>
          </div>

          <div>
            <FieldLabel>{t('anomalies.details_sidebar.start')}</FieldLabel>
            <Skeleton isLoading={isLoading}>
              <TextField
                value={
                  anomalyDetailsQuery.data?.data.observationWindow.start &&
                  format(new Date(anomalyDetailsQuery.data?.data.observationWindow.start), dateTimeWithTimezone)
                }
                readOnly
                data-test-id="anomaly-sidebar-start-field"
              />
            </Skeleton>
          </div>

          <div>
            <FieldLabel>{t('anomalies.details_sidebar.end')}</FieldLabel>
            <Skeleton isLoading={isLoading}>
              <TextField
                value={
                  anomalyDetailsQuery.data?.data.observationWindow.end &&
                  format(new Date(anomalyDetailsQuery.data?.data.observationWindow.end), dateTimeWithTimezone)
                }
                readOnly
                data-test-id="anomaly-sidebar-end-field"
              />
            </Skeleton>
          </div>

          <div>
            <FieldLabel>{t('common.confidence_score')}</FieldLabel>
            <Skeleton isLoading={isLoading}>
              <TextField
                value={anomalyDetailsQuery.data?.data.confidenceScore}
                readOnly
                data-test-id="anomaly-sidebar-score-field"
              />
            </Skeleton>
          </div>

          {!isLoading && (
            <>
              {!!anomalyDetailsQuery.data?.data.entities?.length && (
                <div>
                  <FieldLabel>{t('common.entities')}</FieldLabel>

                  <div className="flex flex-col items-start gap-1" data-test-id="anomaly-sidebar-entities-div">
                    {anomalyDetailsQuery.data?.data.entities.map((entity) => (
                      <EntitiesFilterBadge
                        isActive={getIsFilterActive('entity', entity.value)}
                        key={entity.value}
                        onClick={() => addFilter('entity', entity.value)}
                      >
                        {entity.value}
                      </EntitiesFilterBadge>
                    ))}
                  </div>
                </div>
              )}

              {!!anomalyDetailsQuery.data?.data.dimensions.length && (
                <div>
                  <FieldLabel>{t('anomalies.details_sidebar.dimensions')}</FieldLabel>
                  <div className="flex flex-col items-start gap-1" data-test-id="anomaly-sidebar-dimensions-div">
                    {anomalyDetailsQuery.data?.data.dimensions.map((dimension) => {
                      return (dimension.values || []).map((data) => {
                        const fullValue = `${dimension.name}:${data.value}`;

                        return (
                          <DimensionsFilterBadge
                            isActive={getIsFilterActive('dimension', fullValue)}
                            key={data.value}
                            onClick={() => addFilter('dimension', fullValue)}
                          >
                            {fullValue}
                          </DimensionsFilterBadge>
                        );
                      });
                    })}
                  </div>
                </div>
              )}

              {!!anomalyDetailsQuery.data?.data.facts.length && (
                <div>
                  <FieldLabel>{t('anomalies.details_sidebar.facts')}</FieldLabel>
                  <div className="flex flex-col items-start gap-1" data-test-id="anomaly-sidebar-facts-div">
                    {anomalyDetailsQuery.data?.data.facts.map((fact) => (
                      <Badge key={fact.value} color="gray" size="1" highContrast variant="soft">
                        {fact.name}: {fact.value}
                      </Badge>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Drawer>
  );
};
