import type { ReactNode } from 'react';

import { cn } from 'utils/styles';
import { getRiskScoreRange } from 'utils/detections';
import type { SelectivePartial } from 'types/helpers';
import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { HIGH_RISK_SCORES, LOW_RISK_SCORES, MEDIUM_RISK_SCORES } from 'consts/threats';

import { Flag } from 'components/common/Flag';
import { Badge } from 'components/common/Badge';
import { Cross1Icon, MixerHorizontalIcon, LayersIcon, DestIPIcon, PersonIcon } from 'components/common/Icons';

type ThreatBadgeProps = {
  children: ReactNode;
  isActive?: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  className?: string;
};

type RiskScoreRange = 'high' | 'medium' | 'low';

type RiskScoreBadgeProps = Omit<ThreatBadgeProps, 'children'> &
  (
    | {
        riskScoreRange: RiskScoreRange;
      }
    | { riskScore: number }
  );
const riskScoreHoverMap = {
  high: 'hover:bg-red-6',
  medium: 'hover:bg-orange-6',
  low: 'hover:bg-gray-6',
};

const useFormatRiskScore = () => {
  const t = useTranslate();

  return (value: RiskScoreRange | number) => {
    if (typeof value === 'number') {
      if (HIGH_RISK_SCORES.includes(value)) {
        return t('common.score.high_value', { value });
      }
      if (MEDIUM_RISK_SCORES.includes(value)) {
        return t('common.score.medium_value', { value });
      }
      if (LOW_RISK_SCORES.includes(value)) {
        return t('common.score.low_value', { value });
      }
    }

    switch (value) {
      case 'high':
        return t('common.score.high_range', {
          low: HIGH_RISK_SCORES[0],
          high: HIGH_RISK_SCORES[HIGH_RISK_SCORES.length - 1]!,
        });
      case 'medium':
        return t('common.score.medium_range', {
          low: MEDIUM_RISK_SCORES[0],
          high: MEDIUM_RISK_SCORES[MEDIUM_RISK_SCORES.length - 1]!,
        });
      case 'low':
        return t('common.score.low_range', {
          low: LOW_RISK_SCORES[0],
          high: LOW_RISK_SCORES[LOW_RISK_SCORES.length - 1]!,
        });
      default:
        return value;
    }
  };
};

const handleClick =
  (onClick: (event: React.MouseEvent<HTMLSpanElement>) => void) => (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(e);
  };

export const ThreatRiskScoreBadge = ({ isActive, onClick, className, ...props }: RiskScoreBadgeProps) => {
  const formatRiskScore = useFormatRiskScore();

  let range: RiskScoreRange = 'high';
  if ('riskScore' in props) {
    const { riskScore } = props;
    range = getRiskScoreRange(riskScore);
  } else {
    range = props.riskScoreRange;
  }

  const color = range === 'high' ? 'red' : range === 'medium' ? 'orange' : 'gray';
  const isRange = 'riskScoreRange' in props;

  return (
    <Badge
      size="1"
      variant="soft"
      color={color}
      onClick={onClick && handleClick(onClick)}
      className={cn(
        {
          [`hover:cursor-pointer ${riskScoreHoverMap[range]}`]: onClick,
        },
        className,
      )}
    >
      <span className="uppercase">{formatRiskScore(isRange ? range : props.riskScore)}</span>
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

type StatusBadgeProps = Omit<ThreatBadgeProps, 'children'> & {
  status: 'active' | 'inactive';
};
const statusHoverMap = {
  active: 'hover:bg-orange-6',
  inactive: 'hover:bg-gray-6',
};

export const ThreatStatusBadge = ({ isActive, status, onClick, className }: StatusBadgeProps) => {
  const t = useTranslate();

  const label = status === 'active' ? t('common.threat_active') : t('common.threat_inactive');

  return (
    <Badge
      size="1"
      variant="outline"
      color={status === 'active' ? 'ruby' : 'gray'}
      highContrast
      onClick={onClick && handleClick(onClick)}
      className={cn(
        {
          [`hover:cursor-pointer ${statusHoverMap[status]}`]: onClick,
        },
        className,
      )}
    >
      {label}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

type ThreatEntityBadgeProps = Omit<ThreatBadgeProps, 'children'> & {
  entity: SelectivePartial<components['schemas']['threatEntity'], 'type'>;
};

export const ThreatEntityBadge = ({ entity, isActive, onClick, className }: ThreatEntityBadgeProps) => {
  return (
    <Badge
      size="1"
      variant="soft"
      color="sky"
      onClick={onClick && handleClick(onClick)}
      className={cn(onClick && 'hover:cursor-pointer hover:bg-sky-6', className)}
    >
      {entity.type === 'country' ? (
        <Flag code={entity.value} />
      ) : entity.geo?.countryIsoCode && entity.geo?.countryIsoCode !== 'lo' ? (
        <Flag code={entity.geo.countryIsoCode} />
      ) : (
        <DestIPIcon className="size-3" />
      )}
      {entity.value}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const ThreatDimensionBadge = ({ children, isActive, className, onClick }: ThreatBadgeProps) => {
  return (
    <Badge
      size="1"
      variant="surface"
      color="orange"
      onClick={onClick && handleClick(onClick)}
      className={cn(onClick && 'hover:cursor-pointer hover:bg-accent-6', className)}
    >
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

type ThreatGeoBadgeProps = Omit<ThreatBadgeProps, 'children'> & {
  geo: string;
};

export const ThreatGeoBadge = ({ geo, isActive, className, onClick }: ThreatGeoBadgeProps) => {
  return (
    <Badge
      size="1"
      variant="surface"
      color="orange"
      onClick={onClick && handleClick(onClick)}
      className={cn(onClick && 'hover:cursor-pointer hover:bg-accent-6', className)}
    >
      {geo && geo !== 'lo' && <Flag code={geo} />}
      {geo}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const ThreatPipelineBadge = ({ children, isActive, onClick, className }: ThreatBadgeProps) => {
  return (
    <Badge
      onClick={onClick && handleClick(onClick)}
      className={cn(onClick && 'hover:cursor-pointer hover:bg-brown-6', className)}
      color="brown"
    >
      <MixerHorizontalIcon />
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const ThreatTenantBadge = ({ children, isActive, onClick, className }: ThreatBadgeProps) => {
  return (
    <Badge
      onClick={onClick && handleClick(onClick)}
      className={cn(onClick && 'hover:cursor-pointer hover:bg-gray-6', className)}
      color="gray"
    >
      <LayersIcon />
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

type ThreatWorkflowStatusBadgeProps = Omit<ThreatBadgeProps, 'children'> & {
  workflowStatus: 'open' | 'closed';
};

export const ThreatWorkflowStatusBadge = ({
  workflowStatus,
  className,
  isActive,
  onClick,
}: ThreatWorkflowStatusBadgeProps) => {
  const t = useTranslate();

  return (
    <Badge
      color="gray"
      onClick={onClick && handleClick(onClick)}
      className={cn(className, onClick && 'hover:cursor-pointer hover:bg-gray-6')}
    >
      {t(`common.workflow_status_options.${workflowStatus}`)}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const ThreatAssigneeBadge = ({ assignee }: { assignee: string | null }) => {
  const t = useTranslate();

  return (
    <Badge color="gray">
      <PersonIcon className="size-3" />
      {assignee === null ? t('common.unassigned') : assignee}
    </Badge>
  );
};
