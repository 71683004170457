import { useEffect, useMemo, useState, type ReactNode } from 'react';
import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import { useEnrichments } from 'services/api/enrichments';

import { Drawer } from 'components/common/Drawer';
import { VerticalTabContent, VerticalTabs, VerticalTabsList, VerticalTabTrigger } from 'components/common/VerticalTabs';
import { Authorize } from 'components/common/Authorize';
import { Button } from 'components/common/Button';

import { EditEnrichmentTabItem, EnrichmentEditContent } from './tabs/EnrichmentGeneralDataTab';
import { AssignPipelineContent, AssignPipelineTabItem } from './tabs/AssignPipelineTab';
import type { EnrichmentContentProps } from './EnrichmentForm';
import { DeleteEnrichmentInlineDialog } from './DeleteEnrichmentInlineDialog';

const routeApi = getRouteApi('/_app/settings/enrichments/manage');

type ViewMode = 'create' | 'edit';
export type EnrichmentSidebarProps = {
  onSuccess: EnrichmentContentProps['onSuccess'];
  onDeletion: EnrichmentContentProps['onDeletion'];
};

export function EditEnrichmentSidebar({ onSuccess, onDeletion }: EnrichmentSidebarProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const enrichmentQuery = useEnrichments();
  const searchParams = routeApi.useSearch();
  const viewMode: ViewMode = 'edit';
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const tab =
    (('enrichmentSidebarIntent' in searchParams &&
      searchParams.enrichmentSidebarIntent === viewMode &&
      searchParams.tab) as string) || 'settings';

  const isOpen = 'enrichmentSidebarIntent' in searchParams && searchParams.enrichmentSidebarIntent === viewMode;

  const enrichmentId = ('enrichmentSidebarIntent' in searchParams &&
    searchParams.enrichmentSidebarIntent === viewMode &&
    searchParams.enrichmentId) as string;

  const enrichment = useMemo(
    () => enrichmentQuery.data?.data.find((u) => u.id === enrichmentId),
    [enrichmentId, enrichmentQuery.data],
  );

  const onOpenChange = (value: boolean) => {
    void navigate({
      search: value ? { enrichmentSidebarIntent: viewMode } : {},
    });
  };

  useEffect(() => {
    if (isOpen) {
      setIsDeleteDialogOpen(false);
    }
  }, [isOpen]);

  const errorOrContent = (node: ReactNode) => {
    if (enrichmentQuery.error) {
      return <p>{t('common.errors.failed_to_retrieve_data')}</p>;
    }

    if (enrichmentQuery.isLoading) {
      return <p>{t('common.loading')}</p>;
    }

    if (!enrichment) {
      return <p>{t('settings.tabs.enrichments.subtabs.manage.sidebar.enrichment_not_found')}</p>;
    }

    return node;
  };

  const onDeleteEnrichmentSuccess = () => {
    setIsDeleteDialogOpen(false);

    if (enrichment) {
      onDeletion(enrichment);
      void navigate({
        search: {},
      });
    }
  };

  return (
    <Drawer
      title={t('settings.tabs.enrichments.subtabs.manage.sidebar.edit_title')}
      description={t('settings.tabs.enrichments.subtabs.manage.sidebar.edit_description', {
        enrichmentName: enrichment?.name,
      })}
      mode="modal"
      side="right"
      size="large"
      insetContent
      isOpen={isOpen}
      actions={
        <Authorize
          policy={{
            scope: 'pipeline:delete',
          }}
        >
          <Button size="1" color="red" onClick={() => setIsDeleteDialogOpen(true)}>
            {t('settings.tabs.enrichments.subtabs.manage.sidebar.delete_enrichment_set')}
          </Button>
        </Authorize>
      }
      onOpenChange={onOpenChange}
    >
      <VerticalTabs
        value={tab}
        onValueChange={(tab) => {
          void navigate({
            search: (prev) => ({
              ...prev,
              tab: tab as 'edit-enrichment' | 'assign-pipelines',
            }),
          });
        }}
        size="2"
        orientation="vertical"
        paddingTop={8}
      >
        <VerticalTabsList>
          <VerticalTabTrigger value="edit-enrichment">
            <EditEnrichmentTabItem />
          </VerticalTabTrigger>
          <VerticalTabTrigger value="assign-pipelines">
            <AssignPipelineTabItem />
          </VerticalTabTrigger>
        </VerticalTabsList>

        <VerticalTabContent value="edit-enrichment">
          {isDeleteDialogOpen && enrichment && (
            <DeleteEnrichmentInlineDialog
              enrichmentId={enrichment.id}
              enrichmentName={enrichment.name}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onSuccess={onDeleteEnrichmentSuccess}
            />
          )}
          {errorOrContent(
            enrichment && <EnrichmentEditContent onSuccess={onSuccess} viewMode={viewMode} enrichment={enrichment} />,
          )}
        </VerticalTabContent>
        <VerticalTabContent value="assign-pipelines">
          {isDeleteDialogOpen && enrichment && (
            <DeleteEnrichmentInlineDialog
              enrichmentId={enrichment.id}
              enrichmentName={enrichment.name}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onSuccess={onDeleteEnrichmentSuccess}
            />
          )}
          {errorOrContent(enrichment && <AssignPipelineContent enrichment={enrichment} />)}
        </VerticalTabContent>
      </VerticalTabs>
    </Drawer>
  );
}
