import JsonView from '@uiw/react-json-view';
import { lightTheme } from '@uiw/react-json-view/light';
import { darkTheme } from '@uiw/react-json-view/dark';

import { Text } from 'components/common/Text';
import { Badge } from 'components/common/Badge';
import { ScrollArea } from 'components/common/ScrollArea';
import { Collapsible } from 'components/common/Collapsible';
import { PageHeaderActions } from 'components/common/PageHeaderActions';
import { ClockIcon, LightningBoltIcon, TextAlignLeftIcon } from 'components/common/Icons';

import { cn } from 'utils/styles';
import { useStore } from 'services/store';
import { LOADING_THREATS } from 'mocks/threat';
import { useThreat } from 'services/api/threats';
import type { components } from 'types/schemas/api-schema';
import { HIGH_RISK_SCORES, LOW_RISK_SCORES, MEDIUM_RISK_SCORES } from 'consts/threats';

import { ThreatMetadataPanel } from './ThreatMetadataPanel';
import { ThreatEvidence } from './ThreatEvidence';

const RiskScoreImpactScore = ({ riskScore }: { riskScore: number }) => {
  return (
    <div className="flex w-20 items-center gap-2 py-2 pl-4">
      <div
        className={cn('size-2 rounded-full', {
          'bg-error-10': HIGH_RISK_SCORES.includes(riskScore),
          'bg-warning-a10': MEDIUM_RISK_SCORES.includes(riskScore),
          'bg-info-11': LOW_RISK_SCORES.includes(riskScore),
        })}
      />
      <Text size="2" weight="medium" color="gray">
        {/* TODO Add tkeys when we actually implement this. Wasn't worth it at this point as it might not be the final version */}
        {HIGH_RISK_SCORES.includes(riskScore) ? 'HIGH' : MEDIUM_RISK_SCORES.includes(riskScore) ? 'MED' : 'LOW'}
      </Text>
    </div>
  );
};

const RiskScoreImpactItem = ({
  riskScore,
  title,
  description,
}: {
  riskScore: number;
  title: string;
  description: string;
}) => {
  return (
    <div className="flex items-center bg-translucent">
      <RiskScoreImpactScore riskScore={riskScore} />
      <Text size="2" weight="medium" className="!mr-1">
        {title}
      </Text>
      <Text size="2" color="gray">
        {description}
      </Text>
    </div>
  );
};

const Main = ({ isLoading, threat }: { isLoading: boolean; threat?: components['schemas']['threat'] }) => {
  const theme = useStore((s) => s.theme.theme);

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-2">
          <Text size="2" weight="medium">
            Threat timeline
          </Text>
          <Badge size="1" variant="soft" highContrast color="gray">
            <ClockIcon className="h-3 w-3" aria-hidden />
            Analysis completed 2 hours ago
          </Badge>
        </div>
        <PageHeaderActions />
      </div>

      <main className="mt-3">
        {/* TODO Add tkeys when we actually implement this. Wasn't worth it at this point as it might not be the final version */}
        <Collapsible variant="transparent" Icon={LightningBoltIcon} title={'Risk Assesment - Impact'} defaultOpen>
          <div className="divide-y-[2px] divide-neutral-5">
            <RiskScoreImpactItem
              riskScore={10}
              title="Asset Severity"
              description="192.168.10.25 has a high asset severity (10)"
            />
            <RiskScoreImpactItem
              riskScore={5}
              title="Intel Match"
              description="172.10.18.148 has correlated intel matches from external sources (Zeek Intel)"
            />
            <RiskScoreImpactItem
              riskScore={1}
              title="Number of entity Pairs"
              description="The weight for the number of entities connected in this threat (2 Pairs)"
            />
          </div>
        </Collapsible>

        <ThreatEvidence isLoading={isLoading} evidence={threat?.evidence} />

        <Collapsible variant="transparent" Icon={TextAlignLeftIcon} title={'JSON Display'}>
          <ScrollArea style={{ height: 550 }}>
            {threat && (
              <JsonView
                value={threat}
                style={theme === 'light' ? lightTheme : darkTheme}
                displayDataTypes={false}
                displayObjectSize={false}
                indentWidth={20}
              />
            )}
          </ScrollArea>
        </Collapsible>
      </main>
    </div>
  );
};

export function ThreatDetails({ threatId }: { threatId: string }) {
  const { data: threat } = useThreat(threatId);

  return (
    <div className="flex h-screen bg-neutral-4">
      <div className="flex w-[350px] flex-1 flex-col gap-1">
        <ThreatMetadataPanel isLoading={!threat?.data} threat={threat?.data || LOADING_THREATS[0]!} />
      </div>

      <div className="flex w-[calc(100%-350px)] flex-col gap-4 p-4">
        <Main isLoading={!threat?.data} threat={threat?.data} />
      </div>
    </div>
  );
}
