import type { components } from 'types/schemas/api-schema';

export const LOADING_CUSTOM_ENRICHMENT_SET_METADATA: components['schemas']['customEnrichmentSetMetadata'][] = [
  ...new Array<number>(10),
].map((_, i) => ({
  id: i.toString(),
  name: 'Loading...',
  description: 'Loading...',
  tenantId: '_'.repeat(10),
}));
