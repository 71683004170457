import { useMemo } from 'react';
import _ from 'lodash';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';

import type { paths } from 'types/schemas/api-schema';
import { parseTimerange } from 'utils/dateTimeValidation';
import { type ValidTimerange } from 'consts/dateTime';
import { HIGH_RISK_SCORES, LOW_RISK_SCORES, MEDIUM_RISK_SCORES } from 'consts/threats';

import { api, type APIError } from '.';

const THREATS_CACHE_TIME = 1000 * 60 * 30;

const threatsQueryConfig = {
  staleTime: THREATS_CACHE_TIME,
  cacheTime: THREATS_CACHE_TIME,
};

const parseRiskScore = _.memoize((riskScore: ('high' | 'medium' | 'low' | undefined)[] = []) => {
  const result: number[] = [];

  if (riskScore.includes('high')) {
    result.push(...HIGH_RISK_SCORES);
  }
  if (riskScore.includes('medium')) {
    result.push(...MEDIUM_RISK_SCORES);
  }
  if (riskScore.includes('low')) {
    result.push(...LOW_RISK_SCORES);
  }

  return result;
});

const Route = getRouteApi('/_app/threats');

export const useThreatsParams = () => {
  const searchParams = Route.useSearch();

  const timeRange = 'timeRange' in searchParams ? searchParams.timeRange : undefined;
  const startTime = 'startTime' in searchParams ? searchParams.startTime : undefined;
  const endTime = 'endTime' in searchParams ? searchParams.endTime : undefined;

  const timeParams = useMemo(() => {
    if (timeRange) {
      return parseTimerange(timeRange as ValidTimerange);
    }

    return {
      startTime,
      endTime,
    } as { startTime: string; endTime: string };
  }, [timeRange, startTime, endTime]);

  const params = {
    riskScore: parseRiskScore(searchParams.riskScore),
    dimension: searchParams.dimension,
    entity: searchParams.entity,
    pipelineId: searchParams.pipelineId,
    tenantId: searchParams.tenantId,
    status: searchParams.status,
    geo: searchParams.geo,
    timeRange,
  } as Omit<typeof searchParams, 'timeRange' | 'startTime' | 'endTime' | 'riskScore'> & {
    riskScore: number[];
    timeRange?: string;
  };

  return {
    ...params,
    ...timeParams,
  };
};

export const useThreat = (threatId: string | undefined) => {
  return useQuery<
    paths['/detections/threats/{threatId}']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/threats/{threatId}']['get']['responses']['404']['content']['application/json']>
  >({
    ...threatsQueryConfig,
    queryKey: ['threats', threatId],
    queryFn: () => {
      return api
        .url(`/detections/threats/${threatId}`)
        .get()
        .json<paths['/detections/threats/{threatId}']['get']['responses']['200']['content']['application/json']>();
    },
    enabled: !!threatId,
  });
};

type ParamsWithTimerange<T extends { startTime: string; endTime: string }> = T & { timeRange?: string };

function mapParamsToQueryKey<T extends { startTime: string; endTime: string }>({
  startTime,
  endTime,
  timeRange,
  ...params
}: ParamsWithTimerange<T>) {
  if (timeRange) {
    return {
      ...params,
      timeRange,
    };
  }

  return {
    ...params,
    startTime,
    endTime,
  };
}

function mapParamsToAPIParams<T extends { startTime: string; endTime: string }>({
  startTime,
  endTime,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timeRange,
  ...params
}: ParamsWithTimerange<T>) {
  return {
    ...params,
    startTime,
    endTime,
  };
}

export const useThreats = (
  params: ParamsWithTimerange<paths['/detections/threats/query']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...threatsQueryConfig,
    queryKey: ['threats', 'query', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/query')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/query']['get']['responses']['200']['content']['application/json']>();
    },
    placeholderData: keepPreviousData,
  });
};

export const useThreatsFacets = (
  params: ParamsWithTimerange<paths['/detections/threats/facets']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...threatsQueryConfig,
    queryKey: ['threats', 'facets', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/facets')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/facets']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useThreatsEntityFacets = (
  params: ParamsWithTimerange<paths['/detections/threats/entities/facets']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...threatsQueryConfig,
    queryKey: ['threats', 'entityFacets', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/entities/facets')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/entities/facets']['get']['responses']['200']['content']['application/json']>();
    },
    placeholderData: (p) => p,
  });
};

export const useThreatsHistogram = (
  params: ParamsWithTimerange<paths['/detections/threats/histogram']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...threatsQueryConfig,
    queryKey: ['threats', 'histogram', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/histogram')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/histogram']['get']['responses']['200']['content']['application/json']>();
    },
  });
};
