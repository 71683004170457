import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type paths, type components } from 'types/schemas/api-schema';
import { api, type APIError } from '.';

export const usePipelines = (tenantId?: components['schemas']['tenant']['id']) => {
  const queryKey = tenantId ? ['pipelines', tenantId] : ['pipelines'];
  return useQuery({
    queryKey,
    queryFn: () => {
      const query = tenantId ? { tenantId } : {};
      return api
        .url(`/ingest/pipelines`)
        .query(query)
        .get()
        .json<paths['/ingest/pipelines']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

type GetPipelineResponse =
  paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['200']['content']['application/json'];

export const usePipeline = (pipelineId?: components['schemas']['idString']) => {
  return useQuery<
    GetPipelineResponse,
    APIError<paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['400']['content']['application/json']>
  >({
    queryKey: ['pipeline', pipelineId],
    queryFn: () => {
      return api.url(`/ingest/pipelines/full/${pipelineId}`).get().json<GetPipelineResponse>();
    },
    enabled: !!pipelineId,
  });
};

type CreatePipelineResponse =
  paths['/ingest/pipelines/full']['post']['responses']['201']['content']['application/json'];

export const useCreatePipeline = () => {
  const queryClient = useQueryClient();

  return useMutation<
    CreatePipelineResponse,
    APIError<paths['/ingest/pipelines/full']['post']['responses']['400']['content']['application/json']>,
    paths['/ingest/pipelines/full']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (pipeline) => {
      const response = await api.url('/ingest/pipelines/full').post(pipeline).json<CreatePipelineResponse>();

      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });

      return response;
    },
  });
};

type EditPipelineResponse =
  paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['200']['content']['application/json'];

export const useEditPipeline = (pipelineid: components['schemas']['idString']) => {
  const queryClient = useQueryClient();

  return useMutation<
    EditPipelineResponse,
    APIError<paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['400']['content']['application/json']>,
    components['schemas']['fullPipeline']
  >({
    mutationFn: async (updatedPipeline) => {
      const response = await api
        .url(`/ingest/pipelines/full/${pipelineid}`)
        .put(updatedPipeline)
        .json<EditPipelineResponse>();

      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });

      return response;
    },
  });
};

type FullPipelineResponse =
  paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['200']['content']['application/json'];

export const useFullPipeline = (pipelineId: components['schemas']['idString']) => {
  return useQuery<
    FullPipelineResponse,
    APIError<paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['400']['content']['application/json']>
  >({
    queryKey: ['pipelines', 'full', pipelineId],
    queryFn: () => {
      return api.url(`/ingest/pipelines/full/${pipelineId}`).get().json<FullPipelineResponse>();
    },
    enabled: !!pipelineId,
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useDeletePipeline = (_pipelineId: string) => {
  const queryClient = useQueryClient();

  // TODO replace the error with the path response
  return useMutation<void, APIError<components['schemas']['errorResponse']>>({
    mutationFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return undefined;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });
    },
  });
};
