import { useMemo, useState } from 'react';
import { format } from 'date-fns';

import { fullDateTimeFormat } from 'consts/dateFormats';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';
import type { FlattenedTranslationKeys } from 'types/translations';
import { extractEntitiesFromThreat } from 'utils/detections';

import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { Badge } from 'components/common/Badge';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';
import { Skeleton } from 'components/common/Skeleton';
import { Breadcrumb } from 'components/common/Breadcrumb';
import { CardStackIcon, PersonIcon } from 'components/common/Icons';

import {
  ThreatEntityBadge,
  ThreatPipelineBadge,
  ThreatRiskScoreBadge,
  ThreatTenantBadge,
} from '../Threats/common/ThreatsBadges';

type FieldSelectProps = {
  options: {
    value: string;
    label: string;
    icon: React.ReactNode;
  }[];
};

const FieldSelect = ({ options }: FieldSelectProps) => {
  const [value, setValue] = useState(options[0]?.value ?? '');

  return (
    <Select.Root value={value} onValueChange={setValue} size="2">
      <Select.Trigger variant="soft" color="gray" className="w-full">
        <div className="flex items-center gap-2">{options.find((option) => option.value === value)?.label}</div>
      </Select.Trigger>
      <Select.Content position="popper">
        {options.map((option) => (
          <Select.Item key={option.value} value={option.value}>
            <div className="flex items-center gap-2">
              {option.icon}
              {option.label}
            </div>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const FieldLabel = ({ children }: { children: React.ReactNode }) => {
  return <Label className="!mb-1.5 block font-medium">{children}</Label>;
};

const WORKFLOW_STATUS_OPTIONS: {
  value: string;
  tkey: FlattenedTranslationKeys;
  icon: React.ReactNode;
}[] = [
  { value: 'open', tkey: 'common.workflow_status_options.open', icon: <CardStackIcon /> },
  { value: 'closed', tkey: 'common.workflow_status_options.closed', icon: <CardStackIcon /> },
];

const ASSIGNEE_OPTIONS = [
  { value: 'unassigned', label: 'Unassigned', icon: <PersonIcon /> },
  { value: 'walter-white', label: 'Walter White', icon: <PersonIcon /> },
];

type SidebarInfoPanel = {
  isLoading?: boolean;
  threat: components['schemas']['threat'];
};

export const ThreatMetadataPanel = ({ isLoading, threat }: SidebarInfoPanel) => {
  const t = useTranslate();

  const tenantsQueryData = useTenants();
  const pipelinesQueryData = usePipelines();

  const entities = useMemo(() => {
    return extractEntitiesFromThreat(threat);
  }, [threat]);

  const firstEvidenceCreatedAt = threat.evidence[0]!.createdAt;
  const lastEvidenceCreatedAt = threat.evidence[threat.evidence.length - 1]?.createdAt;

  const shouldDisplayLastEvidence = lastEvidenceCreatedAt && firstEvidenceCreatedAt !== lastEvidenceCreatedAt;

  const isDetailsLoading = isLoading || tenantsQueryData.isLoading || pipelinesQueryData.isLoading;

  const getPipelineName = (pipelineId: string) => {
    return (pipelinesQueryData.data?.data || [])?.find((pipeline) => pipeline.id === pipelineId)?.name ?? pipelineId;
  };

  const getTenantName = (tenantId: string) => {
    return (tenantsQueryData.data?.data || [])?.find((tenant) => tenant.id === tenantId)?.name ?? tenantId;
  };

  return (
    <div className="flex flex-1 flex-col gap-2 border-b border-l border-r border-neutral-a3 bg-neutral-4 p-4">
      <Breadcrumb />

      <Skeleton isLoading={isDetailsLoading}>
        <Text size="4">{threat.name}</Text>
      </Skeleton>

      <div className="space-y-4 py-4">
        <div>
          <div className="flex justify-between">
            <FieldLabel>{t('common.workflow_status')}</FieldLabel>
          </div>
          <Skeleton isLoading={isDetailsLoading}>
            <div>
              <FieldSelect
                options={WORKFLOW_STATUS_OPTIONS.map((o) => ({
                  ...o,
                  label: t(o.tkey),
                }))}
              />
            </div>
          </Skeleton>
        </div>

        <div>
          <div className="flex justify-between">
            <FieldLabel>{t('threats.details_sidebar.assignee')}</FieldLabel>
          </div>
          <Skeleton isLoading={isDetailsLoading}>
            <div>
              <FieldSelect options={ASSIGNEE_OPTIONS} />
            </div>
          </Skeleton>
          <Skeleton isLoading={isDetailsLoading}>
            <div className="inline-flex items-baseline border-b border-accent-7 hover:border-accent-8">
              <Button variant="ghost" className="text-sm font-light hover:bg-transparent">
                {t('threats.details_sidebar.assign_to_me')}
              </Button>
            </div>
          </Skeleton>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <FieldLabel>{t('common.risk_score')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            <ThreatRiskScoreBadge riskScore={threat.riskScore.score} />
          </Skeleton>
        </div>

        <div>
          <FieldLabel>{t('common.tenant')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            <ThreatTenantBadge>{getTenantName(threat.tenantId)}</ThreatTenantBadge>
          </Skeleton>
        </div>

        <div>
          <FieldLabel>{t('common.pipeline')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            {threat.pipelineIds.map((pipelineId) => (
              <ThreatPipelineBadge key={pipelineId}>{getPipelineName(pipelineId)}</ThreatPipelineBadge>
            ))}
          </Skeleton>
        </div>

        <div>
          <FieldLabel>{t('common.entities')}</FieldLabel>
          <div className="grid place-items-start gap-2">
            {entities.map((entity) => {
              return (
                <Skeleton key={entity.value} isLoading={isDetailsLoading}>
                  <ThreatEntityBadge entity={entity} />
                </Skeleton>
              );
            })}
          </div>
        </div>

        <div>
          <FieldLabel>{t('threats.details_sidebar.time.label')}</FieldLabel>
          <div className="space-y-2">
            <Skeleton isLoading={isDetailsLoading}>
              <Badge variant="surface" color="gray">
                {t('threats.details_sidebar.time.first_evidence', {
                  date: format(new Date(firstEvidenceCreatedAt), fullDateTimeFormat),
                })}
              </Badge>
            </Skeleton>
            <Skeleton isLoading={isDetailsLoading}>
              <Badge variant="surface" color="gray">
                {t('threats.details_sidebar.time.threat_created', {
                  date: format(new Date(threat.createdAt), fullDateTimeFormat),
                })}
              </Badge>
            </Skeleton>
            {shouldDisplayLastEvidence && (
              <Skeleton isLoading={isDetailsLoading}>
                <Badge variant="surface" color="gray">
                  {t('threats.details_sidebar.time.last_evidence', {
                    date: format(new Date(lastEvidenceCreatedAt), fullDateTimeFormat),
                  })}
                </Badge>
              </Skeleton>
            )}
            {threat.inactiveAt && (
              <Skeleton isLoading={isDetailsLoading}>
                <Badge variant="surface" color="gray">
                  {t('threats.details_sidebar.time.threat_complete', {
                    date: format(new Date(threat.inactiveAt), fullDateTimeFormat),
                  })}
                </Badge>
              </Skeleton>
            )}
          </div>
        </div>

        <div>
          <FieldLabel>{t('threats.details_sidebar.id')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            <Badge highContrast color="gray">
              {threat.id}
            </Badge>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
