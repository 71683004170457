import { createContext, useCallback, useContext, useState, type ReactNode } from 'react';
import type { Updater, PaginationState } from '@tanstack/react-table';
import { getRouteApi } from '@tanstack/react-router';
import _ from 'lodash';

import type { DateTimeFilter } from 'consts/dateTime';
import { useThreatsParams } from 'services/api/threats';

export type ThreatsFilterProperty = 'pipelineId' | 'dimension' | 'tenantId' | 'entity' | 'riskScore' | 'status' | 'geo';

type HandleFilterClick = (property: ThreatsFilterProperty, value: string) => void;

type ThreatsContextProps = {
  toggleFilter: HandleFilterClick;
  pagination: PaginationState;
  getIsFilterActive: (property: ThreatsFilterProperty, value: string | number) => boolean;
  setPagination: (updater: Updater<PaginationState>) => void;
  changeTimerange: (data: DateTimeFilter) => void;
};

const ThreatsContext = createContext<ThreatsContextProps>({} as ThreatsContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export const useThreatsContext = () => {
  const context = useContext(ThreatsContext);
  if (!context) {
    throw new Error('ThreatsContext must be used within a ThreatsProvider');
  }
  return context;
};

type ThreatsProviderProps = {
  children: ReactNode;
};

const Route = getRouteApi('/_app/threats/');

export const ThreatsProvider = ({ children }: ThreatsProviderProps) => {
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const threatParams = useThreatsParams();
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });

  const changeTimerange = useCallback(
    (data: DateTimeFilter) => {
      void navigate({
        search: (previousParams) => {
          const withoutTimerange = _.omit(previousParams, ['startTime', 'endTime', 'timeRange']);

          return {
            ...withoutTimerange,
            ...data,
          };
        },
      });
    },
    [navigate],
  );

  const toggleFilter: HandleFilterClick = useCallback(
    (property, value) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));

      void navigate({
        search: (prev) => {
          const currentVal = prev?.[property] || [];

          if (currentVal.includes(value)) {
            const newVal = currentVal.filter((val) => val !== value);
            return {
              ...(prev || {}),
              [property]: newVal.length ? newVal : undefined,
              anomalyId: undefined,
            };
          }
          return {
            ...(prev || {}),
            [property]: [...currentVal, value],
            anomalyId: undefined,
          };
        },
      });
    },
    [navigate],
  );

  const getIsFilterActive = useCallback(
    (property: ThreatsFilterProperty, value: string | number) => {
      const activeValues = threatParams[property];
      if (!activeValues?.length) {
        return false;
      }

      if (property === 'riskScore') {
        if (typeof value === 'number') {
          return activeValues.includes(value);
        } else {
          return searchParams.riskScore?.includes(value) || false;
        }
      }

      return activeValues.includes(value);
    },
    [searchParams.riskScore, threatParams],
  );

  return (
    <ThreatsContext.Provider value={{ pagination, setPagination, toggleFilter, changeTimerange, getIsFilterActive }}>
      {children}
    </ThreatsContext.Provider>
  );
};
