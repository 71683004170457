import { useNavigate, getRouteApi } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import { Drawer } from 'components/common/Drawer';
import { VerticalTabContent, VerticalTabTrigger, VerticalTabs, VerticalTabsList } from 'components/common/VerticalTabs';

import { SettingsCreateTabContent, type SettingsCreateTabContentProps, SettingsTabItem } from './tabs/SettingsTab';

type CreateTenantSidebarProps = {
  onSuccess: SettingsCreateTabContentProps['onSuccess'];
};

const routeApi = getRouteApi('/_app/settings/platform/tenants');

export const CreateTenantSidebar = ({ onSuccess }: CreateTenantSidebarProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const searchParams = routeApi.useSearch();

  const isOpen = 'tenantSidebarIntent' in searchParams && searchParams.tenantSidebarIntent === 'create';

  return (
    <Drawer
      title={t('settings.tabs.platform.subtabs.tenants.sidebar.create_title')}
      description={t('settings.tabs.platform.subtabs.tenants.sidebar.create_description')}
      mode="modal"
      side="right"
      insetContent
      isOpen={isOpen}
      onOpenChange={(value) => {
        void navigate({
          search: value ? { tenantSidebarIntent: 'create' } : {},
        });
      }}
    >
      <VerticalTabs value="settings" size="2" orientation="vertical" paddingTop={8}>
        <VerticalTabsList data-test-id="tenant-sidebar-vertical-tabs">
          <VerticalTabTrigger value="settings" data-test-id="tenant-sidebar-settings-button">
            <SettingsTabItem />
          </VerticalTabTrigger>
        </VerticalTabsList>

        <VerticalTabContent value="settings">
          <SettingsCreateTabContent onSuccess={onSuccess} />
        </VerticalTabContent>
      </VerticalTabs>
    </Drawer>
  );
};
