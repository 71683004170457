import { useMemo } from 'react';
import _ from 'lodash';
import { getRouteApi } from '@tanstack/react-router';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { type paths } from 'types/schemas/api-schema';

import type { ValidTimerange } from 'consts/dateTime';
import { HIGH_CONFIDENCE_SCORES, LOW_CONFIDENCE_SCORES, MEDIUM_CONFIDENCE_SCORES } from 'consts/anomalies';
import { parseTimerange } from 'utils/dateTimeValidation';
import { mapParamsToAPIParams, mapParamsToQueryKey, type ParamsWithTimerange } from 'utils/detections';

import { api, type APIError } from '.';

const ANOMALIES_CACHE_TIME = 1000 * 60 * 30;

const anomaliesQueryConfig = {
  staleTime: ANOMALIES_CACHE_TIME,
  cacheTime: ANOMALIES_CACHE_TIME,
};

export const useAnomaly = (anomalyId: string | undefined) => {
  return useQuery<
    paths['/detections/anomalies/{anomalyId}']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/anomalies/{anomalyId}']['get']['responses']['404']['content']['application/json']>
  >({
    ...anomaliesQueryConfig,
    queryKey: ['anomalies', anomalyId],
    queryFn: () => {
      return api
        .url(`/detections/anomalies/${anomalyId}`)
        .get()
        .json<paths['/detections/anomalies/{anomalyId}']['get']['responses']['200']['content']['application/json']>();
    },
    enabled: !!anomalyId,
  });
};

export const useAnomalies = (
  params: ParamsWithTimerange<paths['/detections/anomalies/query']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...anomaliesQueryConfig,
    queryKey: ['anomalies', 'query', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/anomalies/query')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/anomalies/query']['get']['responses']['200']['content']['application/json']>();
    },
    placeholderData: keepPreviousData,
  });
};

export const useAnomalyFacets = (
  params: ParamsWithTimerange<paths['/detections/anomalies/facets']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...anomaliesQueryConfig,
    queryKey: ['anomalies', 'facets', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/anomalies/facets')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/anomalies/facets']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useAnomalyEntityFacets = (
  params: ParamsWithTimerange<paths['/detections/anomalies/entities/facets']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...anomaliesQueryConfig,
    queryKey: ['anomalies', 'entityFacets', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/anomalies/entities/facets')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<
          paths['/detections/anomalies/entities/facets']['get']['responses']['200']['content']['application/json']
        >();
    },
    placeholderData: (p) => p,
  });
};

export const useAnomalyHistogram = (
  params: ParamsWithTimerange<paths['/detections/anomalies/histogram']['get']['parameters']['query']>,
) => {
  return useQuery({
    ...anomaliesQueryConfig,
    queryKey: ['anomalies', 'histogram', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/anomalies/histogram')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/anomalies/histogram']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

const Route = getRouteApi('/_app/anomalies');

const parseConfidenceScores = _.memoize((confidenceScore: ('high' | 'medium' | 'low' | undefined)[] = []) => {
  const result: number[] = [];

  if (confidenceScore.includes('high')) {
    result.push(...HIGH_CONFIDENCE_SCORES);
  }
  if (confidenceScore.includes('medium')) {
    result.push(...MEDIUM_CONFIDENCE_SCORES);
  }
  if (confidenceScore.includes('low')) {
    result.push(...LOW_CONFIDENCE_SCORES);
  }

  return result;
});

export const useAnomalyParams = () => {
  const searchParams = Route.useSearch();

  const timeRange = 'timeRange' in searchParams ? searchParams.timeRange : undefined;
  const startTime = 'startTime' in searchParams ? searchParams.startTime : undefined;
  const endTime = 'endTime' in searchParams ? searchParams.endTime : undefined;

  const timeParams = useMemo(() => {
    if (timeRange) {
      return parseTimerange(timeRange as ValidTimerange);
    }

    return {
      startTime,
      endTime,
    } as { startTime: string; endTime: string };
  }, [timeRange, startTime, endTime]);

  const params = {
    confidenceScore: parseConfidenceScores(searchParams.confidenceScore),
    dimension: searchParams.dimension,
    entity: searchParams.entity,
    pipelineId: searchParams.pipelineId,
    tenantId: searchParams.tenantId,
    timeRange,
  } as Omit<typeof searchParams, 'timeRange' | 'startTime' | 'endTime' | 'confidenceScore'> & {
    confidenceScore: number[];
    timeRange?: string;
  };

  return {
    ...params,
    ...timeParams,
  };
};
